.pagination {
	& > li {
		& > a,
		& > span {
			font-weight: 600;
			line-height: 1em;
			padding: 10px 18px;
			@include font($fw-m);
			color: map-get($theme, text);
		}
		&:first-child,
		&:last-child {
			& > a,
			& > span {
				padding: 3px 8px;
				i {
					font-size: 2.2em;
				}
			}
		}
	}
	.disabled {
		 & > a,
		 & > span {
			 @include font($prFont);
		 }
	}
}
