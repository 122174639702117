@mixin rippleEffect($size: em(2px), $color: #000, $op: 0.08, $d1: 2s, $d2: 3.25, $loop: "infinite") {
  // &:after,
  &:before {
    right: 0;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    content: " ";
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    border: $size solid rgba($color, $op);
  }
  &:before {
    @include animation("pulsate #{$d1} #{$loop}");
  }
  // &:after {
  //     @include animation("pulsate #{$d2} infinite");
  // }
}
body {
  background: map-get($theme, write);
}

main {
  //padding: rem(40px) 0;
  .actionBar {
    margin: rem(-40px) 0 rem(40px);
    // padding: rem(5px);
  }
}

.appPage {
  // padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 100vh;
  position: absolute;
  min-height: rem(1920px);
  background: map-get($theme, bg); // height: 100vh;
  // overflow: scroll; // /* has to be scroll, not auto
  // overflow-x: hidden;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
  // @extend %animateFast;
}

.ib-m {
  &.row {
    font-size: 0;
    display: block;
    & > div {
      float: none;
      font-size: 1rem;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
