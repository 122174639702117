$icomoon-font-family: "icon" !default;
$icomoon-font-path: "fonts" !default;

$i-chat_mn: "\e941";
$i-note: "\e942";
$i-call: "\e93f";
$i-call-back: "\e940";
$i-half-service: "\e93a";
$i-full-service: "\e93b";
$i-tick: "\e900";
$i-checktik: "\e901";
$i-error: "\e000";
$i-error_outline: "\e001";
$i-warning: "\e002";
$i-add_alert: "\e003";
$i-album: "\e019";
$i-av_timer: "\e01b";
$i-closed_caption: "\e01c";
$i-equalizer: "\e01d";
$i-explicit: "\e01e";
$i-fast_forward: "\e01f";
$i-fast_rewind: "\e020";
$i-games: "\e021";
$i-hearing: "\e023";
$i-high_quality: "\e024";
$i-mic: "\e029";
$i-mic_none: "\e02a";
$i-mic_off: "\e02b";
$i-library_books: "\e02f";
$i-library_music: "\e030";
$i-new_releases: "\e031";
$i-not_interested: "\e033";
$i-pause: "\e034";
$i-pause_circle_filled: "\e035";
$i-pause_circle_outline: "\e036";
$i-play_arrow: "\e037";
$i-play_circle_filled: "\e038";
$i-play_circle_outline: "\e039";
$i-playlist_add: "\e03b";
$i-queue: "\e03c";
$i-queue_music: "\e03d";
$i-radio: "\e03e";
$i-recent_actors: "\e03f";
$i-repeat: "\e040";
$i-repeat_one: "\e041";
$i-replay: "\e042";
$i-shuffle: "\e043";
$i-skip_next: "\e044";
$i-skip_previous: "\e045";
$i-snooze: "\e046";
$i-stop: "\e047";
$i-subtitles: "\e048";
$i-surround_sound: "\e049";
$i-video_library: "\e04a";
$i-videocam: "\e04b";
$i-videocam_off: "\e04c";
$i-volume_mute: "\e04e";
$i-volume_off: "\e04f";
$i-volume_up: "\e050";
$i-web: "\e051";
$i-hd: "\e052";
$i-sort_by_alpha: "\e053";
$i-airplay: "\e055";
$i-forward_30: "\e057";
$i-forward_5: "\e058";
$i-replay_10: "\e059";
$i-replay_30: "\e05a";
$i-replay_5: "\e05b";
$i-add_to_queue: "\e05c";
$i-fiber_dvr: "\e05d";
$i-fiber_new: "\e05e";
$i-playlist_play: "\e05f";
$i-art_track: "\e060";
$i-fiber_manual_record: "\e061";
$i-fiber_smart_record: "\e062";
$i-music_video: "\e063";
$i-subscriptions: "\e064";
$i-playlist_add_check: "\e065";
$i-queue_play_next: "\e066";
$i-remove_from_queue: "\e067";
$i-slow_motion_video: "\e068";
$i-web_asset: "\e069";
$i-branding_watermark: "\e06b";
$i-call_to_action: "\e06c";
$i-featured_video: "\e06e";
$i-video_call: "\e070";
$i-video_label: "\e071";
$i-call_end: "\e0b1";
$i-call_made: "\e0b2";
$i-call_missed: "\e0b4";
$i-call_received: "\e0b5";
$i-call_split: "\e0b6";
$i-chat: "\e0b7";
$i-clear_all: "\e0b8";
$i-comment: "\e0b9";
$i-contacts: "\e0ba";
$i-dialer_sip: "\e0bb";
$i-dialpad: "\e0bc";
$i-import_export: "\e0c3";
$i-invert_colors_off: "\e0c4";
$i-live_help: "\e0c6";
$i-location_off: "\e0c7";
$i-message: "\e0c9";
$i-chat_bubble: "\e0ca";
$i-chat_bubble_outline: "\e0cb";
$i-portable_wifi_off: "\e0ce";
$i-contact_phone: "\e0cf";
$i-contact_mail: "\e0d0";
$i-ring_volume: "\e0d1";
$i-speaker_phone: "\e0d2";
$i-stay_primary_landscape: "\e0d5";
$i-stay_primary_portrait: "\e0d6";
$i-swap_calls: "\e0d7";
$i-textsms: "\e0d8";
$i-voicemail: "\e0d9";
$i-vpn_key: "\e0da";
$i-phonelink_erase: "\e0db";
$i-phonelink_lock: "\e0dc";
$i-phonelink_ring: "\e0dd";
$i-phonelink_setup: "\e0de";
$i-present_to_all: "\e0df";
$i-import_contacts: "\e0e0";
$i-mail_outline: "\e0e1";
$i-screen_share: "\e0e2";
$i-stop_screen_share: "\e0e3";
$i-call_missed_outgoing: "\e0e4";
$i-rss_feed: "\e0e5";
$i-add: "\e145";
$i-add_box: "\e146";
$i-add_circle: "\e147";
$i-archive: "\e149";
$i-backspace: "\e14a";
$i-block: "\e14b";
$i-content_copy: "\e14d";
$i-content_cut: "\e14e";
$i-content_paste: "\e14f";
$i-drafts: "\e151";
$i-filter_list: "\e152";
$i-flag: "\e153";
$i-forward: "\e154";
$i-gesture: "\e155";
$i-inbox: "\e156";
$i-link: "\e157";
$i-markunread: "\e159";
$i-redo: "\e15a";
$i-remove: "\e15b";
$i-remove_circle: "\e15c";
$i-remove_circle_outline: "\e15d";
$i-reply: "\e15e";
$i-reply_all: "\e15f";
$i-report: "\e160";
$i-save: "\e161";
$i-select_all: "\e162";
$i-send: "\e163";
$i-sort: "\e164";
$i-text_format: "\e165";
$i-undo: "\e166";
$i-font_download: "\e167";
$i-move_to_inbox: "\e168";
$i-unarchive: "\e169";
$i-next_week: "\e16a";
$i-weekend: "\e16b";
$i-delete_sweep: "\e16c";
$i-low_priority: "\e16d";
$i-access_alarms: "\e191";
$i-airplanemode_inactive: "\e194";
$i-battery_alert: "\e19c";
$i-battery_charging_full: "\e1a3";
$i-battery_std: "\e1a5";
$i-battery_unknown: "\e1a6";
$i-bluetooth: "\e1a7";
$i-bluetooth_connected: "\e1a8";
$i-bluetooth_disabled: "\e1a9";
$i-bluetooth_searching: "\e1aa";
$i-brightness_auto: "\e1ab";
$i-brightness_high: "\e1ac";
$i-brightness_low: "\e1ad";
$i-brightness_medium: "\e1ae";
$i-data_usage: "\e1af";
$i-developer_mode: "\e1b0";
$i-dvr: "\e1b2";
$i-location_disabled: "\e1b6";
$i-location_searching: "\e1b7";
$i-graphic_eq: "\e1b8";
$i-network_cell: "\e1b9";
$i-network_wifi: "\e1ba";
$i-nfc: "\e1bb";
$i-wallpaper: "\e1bc";
$i-widgets: "\e1bd";
$i-screen_lock_landscape: "\e1be";
$i-screen_lock_portrait: "\e1bf";
$i-screen_lock_rotation: "\e1c0";
$i-screen_rotation: "\e1c1";
$i-sd_storage: "\e1c2";
$i-settings_system_daydream: "\e1c3";
$i-signal_cellular_4_bar: "\e1c8";
$i-signal_cellular_connected_no_internet_4_bar: "\e1cd";
$i-signal_cellular_no_sim: "\e1ce";
$i-signal_cellular_null: "\e1cf";
$i-signal_cellular_off: "\e1d0";
$i-signal_wifi_4_bar: "\e1d8";
$i-signal_wifi_4_bar_lock: "\e1d9";
$i-signal_wifi_off: "\e1da";
$i-storage: "\e1db";
$i-usb: "\e1e0";
$i-wifi_lock: "\e1e1";
$i-wifi_tethering: "\e1e2";
$i-attach_file: "\e226";
$i-attach_money: "\e227";
$i-border_all: "\e228";
$i-border_bottom: "\e229";
$i-border_clear: "\e22a";
$i-border_color: "\e22b";
$i-border_horizontal: "\e22c";
$i-border_inner: "\e22d";
$i-border_left: "\e22e";
$i-border_outer: "\e22f";
$i-border_right: "\e230";
$i-border_style: "\e231";
$i-border_top: "\e232";
$i-border_vertical: "\e233";
$i-format_align_center: "\e234";
$i-format_align_justify: "\e235";
$i-format_align_left: "\e236";
$i-format_align_right: "\e237";
$i-format_bold: "\e238";
$i-format_clear: "\e239";
$i-format_color_fill: "\e23a";
$i-format_color_reset: "\e23b";
$i-format_color_text: "\e23c";
$i-format_indent_decrease: "\e23d";
$i-format_indent_increase: "\e23e";
$i-format_italic: "\e23f";
$i-format_line_spacing: "\e240";
$i-format_list_bulleted: "\e241";
$i-format_list_numbered: "\e242";
$i-format_paint: "\e243";
$i-format_quote: "\e244";
$i-format_size: "\e245";
$i-format_strikethrough: "\e246";
$i-format_textdirection_l_to_r: "\e247";
$i-format_textdirection_r_to_l: "\e248";
$i-format_underlined: "\e249";
$i-functions: "\e24a";
$i-insert_comment: "\e24c";
$i-insert_drive_file: "\e24d";
$i-insert_invitation: "\e24f";
$i-merge_type: "\e252";
$i-Rmode_comment: "\e253";
$i-mode_edit: "\e254";
$i-publish: "\e255";
$i-space_bar: "\e256";
$i-strikethrough_s: "\e257";
$i-vertical_align_bottom: "\e258";
$i-vertical_align_center: "\e259";
$i-vertical_align_top: "\e25a";
$i-wrap_text: "\e25b";
$i-money_off: "\e25c";
$i-drag_handle: "\e25d";
$i-format_shapes: "\e25e";
$i-highlight: "\e25f";
$i-linear_scale: "\e260";
$i-short_text: "\e261";
$i-text_fields: "\e262";
$i-monetization_on: "\e263";
$i-title: "\e264";
$i-attachment: "\e2bc";
$i-cloud_circle: "\e2be";
$i-cloud_done: "\e2bf";
$i-cloud_download: "\e2c0";
$i-cloud_off: "\e2c1";
$i-cloud_queue: "\e2c2";
$i-cloud_upload: "\e2c3";
$i-file_upload: "\e2c6";
$i-folder: "\e2c7";
$i-folder_open: "\e2c8";
$i-folder_shared: "\e2c9";
$i-create_new_folder: "\e2cc";
$i-cast: "\e307";
$i-cast_connected: "\e308";
$i-desktop_mac: "\e30b";
$i-desktop_windows: "\e30c";
$i-developer_board: "\e30d";
$i-dock: "\e30e";
$i-headset: "\e310";
$i-headset_mic: "\e311";
$i-keyboard: "\e312";
$i-keyboard_arrow_down: "\e313";
$i-keyboard_arrow_left: "\e314";
$i-keyboard_arrow_right: "\e315";
$i-keyboard_arrow_up: "\e316";
$i-keyboard_arrow_up1: "\e319";
$i-keyboard_backspace: "\e317";
$i-keyboard_capslock: "\e318";
$i-keyboard_hide: "\e31a";
$i-keyboard_return: "\e31b";
$i-keyboard_tab: "\e31c";
$i-keyboard_voice: "\e31d";
$i-laptop: "\e31e";
$i-laptop_chromebook: "\e31f";
$i-laptop_mac: "\e320";
$i-laptop_windows: "\e321";
$i-memory: "\e322";
$i-mouse: "\e323";
$i-phone_android: "\e324";
$i-phone_iphone: "\e325";
$i-phonelink: "\e326";
$i-phonelink_off: "\e327";
$i-router: "\e328";
$i-scanner: "\e329";
$i-security: "\e32a";
$i-sim_card: "\e32b";
$i-speaker: "\e32d";
$i-speaker_group: "\e32e";
$i-tablet: "\e32f";
$i-tablet_android: "\e330";
$i-tablet_mac: "\e331";
$i-toys: "\e332";
$i-tv: "\e333";
$i-watch: "\e334";
$i-device_hub: "\e335";
$i-power_input: "\e336";
$i-devices_other: "\e337";
$i-videogame_asset: "\e338";
$i-adjust: "\e39e";
$i-assistant: "\e39f";
$i-audiotrack: "\e3a1";
$i-blur_circular: "\e3a2";
$i-blur_linear: "\e3a3";
$i-blur_off: "\e3a4";
$i-blur_on: "\e3a5";
$i-brightness_1: "\e3a6";
$i-brightness_2: "\e3a7";
$i-brightness_3: "\e3a8";
$i-brightness_4: "\e3a9";
$i-broken_image: "\e3ad";
$i-brush: "\e3ae";
$i-camera: "\e3af";
$i-camera_front: "\e3b1";
$i-camera_rear: "\e3b2";
$i-camera_roll: "\e3b3";
$i-center_focus_strong: "\e3b4";
$i-center_focus_weak: "\e3b5";
$i-colorize: "\e3b8";
$i-compare: "\e3b9";
$i-control_point: "\e3ba";
$i-control_point_duplicate: "\e3bb";
$i-crop_16_9: "\e3bc";
$i-crop_3_2: "\e3bd";
$i-crop: "\e3be";
$i-crop_7_5: "\e3c0";
$i-crop_din: "\e3c1";
$i-crop_free: "\e3c2";
$i-crop_landscape: "\e3c3";
$i-crop_original: "\e3c4";
$i-crop_portrait: "\e3c5";
$i-crop_square: "\e3c6";
$i-dehaze: "\e3c7";
$i-details: "\e3c8";
$i-exposure: "\e3ca";
$i-exposure_neg_1: "\e3cb";
$i-exposure_neg_2: "\e3cc";
$i-exposure_plus_1: "\e3cd";
$i-exposure_plus_2: "\e3ce";
$i-exposure_zero: "\e3cf";
$i-filter_1: "\e3d0";
$i-filter_2: "\e3d1";
$i-filter_3: "\e3d2";
$i-filter: "\e3d3";
$i-filter_4: "\e3d4";
$i-filter_5: "\e3d5";
$i-filter_6: "\e3d6";
$i-filter_7: "\e3d7";
$i-filter_8: "\e3d8";
$i-filter_9: "\e3d9";
$i-filter_9_plus: "\e3da";
$i-filter_b_and_w: "\e3db";
$i-filter_center_focus: "\e3dc";
$i-filter_drama: "\e3dd";
$i-filter_frames: "\e3de";
$i-filter_none: "\e3e0";
$i-filter_tilt_shift: "\e3e2";
$i-filter_vintage: "\e3e3";
$i-flare: "\e3e4";
$i-flash_auto: "\e3e5";
$i-flash_off: "\e3e6";
$i-flash_on: "\e3e7";
$i-flip: "\e3e8";
$i-gradient: "\e3e9";
$i-grain: "\e3ea";
$i-grid_off: "\e3eb";
$i-grid_on: "\e3ec";
$i-hdr_off: "\e3ed";
$i-hdr_on: "\e3ee";
$i-hdr_strong: "\e3f1";
$i-hdr_weak: "\e3f2";
$i-healing: "\e3f3";
$i-image_aspect_ratio: "\e3f5";
$i-iso: "\e3f6";
$i-leak_add: "\e3f8";
$i-leak_remove: "\e3f9";
$i-lens: "\e3fa";
$i-looks_3: "\e3fb";
$i-looks: "\e3fc";
$i-looks_4: "\e3fd";
$i-looks_5: "\e3fe";
$i-looks_6: "\e3ff";
$i-looks_one: "\e400";
$i-looks_two: "\e401";
$i-loupe: "\e402";
$i-monochrome_photos: "\e403";
$i-movie_creation: "\e404";
$i-music_note: "\e405";
$i-nature: "\e406";
$i-nature_people: "\e407";
$i-navigate_before: "\e408";
$i-navigate_next: "\e409";
$i-palette: "\e40a";
$i-panorama: "\e40b";
$i-panorama_horizontal: "\e40d";
$i-panorama_vertical: "\e40e";
$i-panorama_wide_angle: "\e40f";
$i-photo: "\e410";
$i-photo_album: "\e411";
$i-photo_camera: "\e412";
$i-photo_library: "\e413";
$i-picture_as_pdf: "\e415";
$i-portrait: "\e416";
$i-rotate_90_degrees_ccw: "\e418";
$i-rotate_left: "\e419";
$i-rotate_right: "\e41a";
$i-slideshow: "\e41b";
$i-straighten: "\e41c";
$i-style: "\e41d";
$i-switch_camera: "\e41e";
$i-switch_video: "\e41f";
$i-tag_faces: "\e420";
$i-texture: "\e421";
$i-timelapse: "\e422";
$i-timer_10: "\e423";
$i-timer_3: "\e424";
$i-timer: "\e425";
$i-timer_off: "\e426";
$i-tonality: "\e427";
$i-transform: "\e428";
$i-tune: "\e429";
$i-view_comfy: "\e42a";
$i-view_compact: "\e42b";
$i-wb_auto: "\e42c";
$i-wb_cloudy: "\e42d";
$i-wb_incandescent: "\e42e";
$i-wb_sunny: "\e430";
$i-collections_bookmark: "\e431";
$i-photo_size_select_actual: "\e432";
$i-photo_size_select_large: "\e433";
$i-photo_size_select_small: "\e434";
$i-vignette: "\e435";
$i-wb_iridescent: "\e436";
$i-crop_rotate: "\e437";
$i-linked_camera: "\e438";
$i-add_a_photo: "\e439";
$i-movie_filter: "\e43a";
$i-photo_filter: "\e43b";
$i-burst_mode: "\e43c";
$i-beenhere: "\e52d";
$i-directions: "\e52e";
$i-directions_bike: "\e52f";
$i-directions_bus: "\e530";
$i-directions_car: "\e531";
$i-directions_boat: "\e532";
$i-directions_railway: "\e534";
$i-directions_transit: "\e535";
$i-directions_walk: "\e536";
$i-flight: "\e539";
$i-layers: "\e53b";
$i-layers_clear: "\e53c";
$i-local_airport: "\e53d";
$i-local_atm: "\e53e";
$i-local_bar: "\e540";
$i-local_cafe: "\e541";
$i-local_car_wash: "\e542";
$i-local_convenience_store: "\e543";
$i-local_drink: "\e544";
$i-local_florist: "\e545";
$i-local_gas_station: "\e546";
$i-local_hospital: "\e548";
$i-local_hotel: "\e549";
$i-local_laundry_service: "\e54a";
$i-local_library: "\e54b";
$i-local_mall: "\e54c";
$i-local_offer: "\e54e";
$i-local_parking: "\e54f";
$i-local_pharmacy: "\e550";
$i-local_pizza: "\e552";
$i-local_play: "\e553";
$i-local_shipping: "\e558";
$i-local_taxi: "\e559";
$i-person_pin: "\e55a";
$i-map: "\e55b";
$i-my_location: "\e55c";
$i-navigation: "\e55d";
$i-pin_drop: "\e55e";
$i-rate_review: "\e560";
$i-restaurant_menu: "\e561";
$i-satellite: "\e562";
$i-store_mall_directory: "\e563";
$i-terrain: "\e564";
$i-traffic: "\e565";
$i-directions_run: "\e566";
$i-add_location: "\e567";
$i-edit_location: "\e568";
$i-near_me: "\e569";
$i-person_pin_circle: "\e56a";
$i-zoom_out_map: "\e56b";
$i-restaurant: "\e56c";
$i-ev_station: "\e56d";
$i-streetview: "\e56e";
$i-subway: "\e56f";
$i-train: "\e570";
$i-tram: "\e571";
$i-transfer_within_a_station: "\e572";
$i-apps: "\e5c3";
$i-arrow_back: "\e5c4";
$i-arrow_drop_down: "\e5c5";
$i-arrow_drop_down_circle: "\e5c6";
$i-arrow_drop_up: "\e5c7";
$i-arrow_forward: "\e5c8";
$i-cancel: "\e5c9";
$i-close: "\e5cd";
$i-expand_less: "\e5ce";
$i-expand_more: "\e5cf";
$i-fullscreen: "\e5d0";
$i-fullscreen_exit: "\e5d1";
$i-menu: "\e5d2";
$i-more_horiz: "\e5d3";
$i-more_vert: "\e5d4";
$i-refresh: "\e5d5";
$i-unfold_less: "\e5d6";
$i-unfold_more: "\e5d7";
$i-arrow_upward: "\e5d8";
$i-subdirectory_arrow_left: "\e5d9";
$i-subdirectory_arrow_right: "\e5da";
$i-arrow_downward: "\e5db";
$i-first_page: "\e5dc";
$i-last_page: "\e5dd";
$i-adb: "\e60e";
$i-disc_full: "\e610";
$i-do_not_disturb_alt: "\e611";
$i-event_available: "\e614";
$i-event_busy: "\e615";
$i-event_note: "\e616";
$i-folder_special: "\e617";
$i-mms: "\e618";
$i-more: "\e619";
$i-network_locked: "\e61a";
$i-phone_bluetooth_speaker: "\e61b";
$i-phone_forwarded: "\e61c";
$i-phone_in_talk: "\e61d";
$i-phone_locked: "\e61e";
$i-phone_missed: "\e61f";
$i-phone_paused: "\e620";
$i-sim_card_alert: "\e624";
$i-sms_failed: "\e626";
$i-sync: "\e627";
$i-sync_disabled: "\e628";
$i-sync_problem: "\e629";
$i-system_update: "\e62a";
$i-tap_and_play: "\e62b";
$i-time_to_leave: "\e62c";
$i-vibration: "\e62d";
$i-voice_chat: "\e62e";
$i-vpn_lock: "\e62f";
$i-airline_seat_flat: "\e630";
$i-airline_seat_flat_angled: "\e631";
$i-airline_seat_individual_suite: "\e632";
$i-airline_seat_legroom_extra: "\e633";
$i-airline_seat_legroom_normal: "\e634";
$i-airline_seat_legroom_reduced: "\e635";
$i-airline_seat_recline_extra: "\e636";
$i-airline_seat_recline_normal: "\e637";
$i-confirmation_number: "\e638";
$i-live_tv: "\e639";
$i-ondemand_video: "\e63b";
$i-personal_video: "\e63c";
$i-power: "\e63d";
$i-wc: "\e63e";
$i-enhanced_encryption: "\e63f";
$i-network_check: "\e640";
$i-no_encryption: "\e641";
$i-rv_hookup: "\e642";
$i-do_not_disturb_off: "\e643";
$i-wifi: "\e644";
$i-priority_high: "\e645";
$i-pie_chart: "\e6c4";
$i-pie_chart_outlined: "\e6c5";
$i-bubble_chart: "\e6dd";
$i-multiline_chart: "\e6df";
$i-show_chart: "\e6e1";
$i-cake: "\e7e9";
$i-domain: "\e7ee";
$i-group_add: "\e7f0";
$i-location_city: "\e7f1";
$i-mood_bad: "\e7f3";
$i-notifications: "\e7f4";
$i-notifications_none: "\e7f5";
$i-notifications_off: "\e7f6";
$i-notifications_active: "\e7f7";
$i-notifications_paused: "\e7f8";
$i-pages: "\e7f9";
$i-party_mode: "\e7fa";
$i-people: "\e7fb";
$i-people_outline: "\e7fc";
$i-person: "\e7fd";
$i-person_add: "\e7fe";
$i-person_outline: "\e7ff";
$i-plus_one: "\e800";
$i-poll: "\e801";
$i-public: "\e80b";
$i-school: "\e80c";
$i-share: "\e80d";
$i-whatshot: "\e80e";
$i-sentiment_dissatisfied: "\e811";
$i-sentiment_neutral: "\e812";
$i-sentiment_satisfied: "\e813";
$i-sentiment_very_dissatisfied: "\e814";
$i-sentiment_very_satisfied: "\e815";
$i-check_box: "\e834";
$i-check_box_outline_blank: "\e835";
$i-radio_button_unchecked: "\e836";
$i-radio_button_checked: "\e837";
$i-star: "\e838";
$i-star_half: "\e839";
$i-star_border: "\e83a";
$i-3d_rotation: "\e84d";
$i-accessibility: "\e84e";
$i-account_balance: "\e84f";
$i-account_balance_wallet: "\e850";
$i-account_box: "\e851";
$i-account_circle: "\e853";
$i-add_shopping_cart: "\e854";
$i-alarm: "\e855";
$i-alarm_add: "\e856";
$i-alarm_off: "\e857";
$i-alarm_on: "\e858";
$i-android: "\e859";
$i-announcement: "\e85a";
$i-aspect_ratio: "\e85b";
$i-assignment: "\e85d";
$i-assignment_ind: "\e85e";
$i-assignment_late: "\e85f";
$i-assignment_return: "\e860";
$i-assignment_returned: "\e861";
$i-assignment_turned_in: "\e862";
$i-autorenew: "\e863";
$i-bug_report: "\e868";
$i-build: "\e869";
$i-cached: "\e86a";
$i-change_history: "\e86b";
$i-check_circle: "\e86c";
$i-chrome_reader_mode: "\e86d";
$i-class: "\e86e";
$i-code: "\e86f";
$i-dashboard: "\e871";
$i-delete: "\e872";
$i-description: "\e873";
$i-dns: "\e875";
$i-done: "\e876";
$i-done_all: "\e877";
$i-exit_to_app: "\e879";
$i-explore: "\e87a";
$i-extension: "\e87b";
$i-face: "\e87c";
$i-favorite: "\e87d";
$i-favorite_border: "\e87e";
$i-find_in_page: "\e880";
$i-find_replace: "\e881";
$i-flip_to_back: "\e882";
$i-flip_to_front: "\e883";
$i-get_app: "\e884";
$i-group_work: "\e886";
$i-help: "\e887";
$i-highlight_off: "\e888";
$i-home: "\e88a";
$i-hourglass_empty: "\e88b";
$i-hourglass_full: "\e88c";
$i-info: "\e88e";
$i-info_outline: "\e88f";
$i-input: "\e890";
$i-invert_colors: "\e891";
$i-label: "\e892";
$i-label_outline: "\e893";
$i-language: "\e894";
$i-list: "\e896";
$i-lock: "\e897";
$i-lock_open: "\e898";
$i-lock_outline: "\e899";
$i-loyalty: "\e89a";
$i-markunread_mailbox: "\e89b";
$i-note_add: "\e89c";
$i-open_in_browser: "\e89d";
$i-open_in_new: "\e89e";
$i-open_with: "\e89f";
$i-pageview: "\e8a0";
$i-payment: "\e8a1";
$i-perm_camera_mic: "\e8a2";
$i-perm_contact_calendar: "\e8a3";
$i-perm_data_setting: "\e8a4";
$i-perm_device_information: "\e8a5";
$i-perm_media: "\e8a7";
$i-perm_phone_msg: "\e8a8";
$i-perm_scan_wifi: "\e8a9";
$i-picture_in_picture: "\e8aa";
$i-polymer: "\e8ab";
$i-power_settings_new: "\e8ac";
$i-print: "\e8ad";
$i-question_answer: "\e8af";
$i-receipt: "\e8b0";
$i-redeem: "\e8b1";
$i-restore: "\e8b3";
$i-room: "\e8b4";
$i-schedule: "\e8b5";
$i-search: "\e8b6";
$i-settings: "\e8b8";
$i-settings_applications: "\e8b9";
$i-settings_backup_restore: "\e8ba";
$i-settings_bluetooth: "\e8bb";
$i-settings_cell: "\e8bc";
$i-settings_brightness: "\e8bd";
$i-settings_ethernet: "\e8be";
$i-settings_input_antenna: "\e8bf";
$i-settings_input_composite: "\e8c1";
$i-settings_input_hdmi: "\e8c2";
$i-settings_input_svideo: "\e8c3";
$i-settings_overscan: "\e8c4";
$i-settings_phone: "\e8c5";
$i-settings_power: "\e8c6";
$i-settings_remote: "\e8c7";
$i-settings_voice: "\e8c8";
$i-shop: "\e8c9";
$i-shop_two: "\e8ca";
$i-shopping_basket: "\e8cb";
$i-shopping_cart: "\e8cc";
$i-speaker_notes: "\e8cd";
$i-spellcheck: "\e8ce";
$i-stars: "\e8d0";
$i-subject: "\e8d2";
$i-supervisor_account: "\e8d3";
$i-swap_horiz: "\e8d4";
$i-swap_vert: "\e8d5";
$i-swap_vertical_circle: "\e8d6";
$i-system_update_alt: "\e8d7";
$i-tab: "\e8d8";
$i-tab_unselected: "\e8d9";
$i-theaters: "\e8da";
$i-thumb_down: "\e8db";
$i-thumb_up: "\e8dc";
$i-thumbs_up_down: "\e8dd";
$i-toc: "\e8de";
$i-today: "\e8df";
$i-toll: "\e8e0";
$i-track_changes: "\e8e1";
$i-translate: "\e8e2";
$i-trending_down: "\e8e3";
$i-trending_flat: "\e8e4";
$i-trending_up: "\e8e5";
$i-turned_in: "\e8e6";
$i-turned_in_not: "\e8e7";
$i-verified_user: "\e8e8";
$i-view_agenda: "\e8e9";
$i-view_array: "\e8ea";
$i-view_carousel: "\e8eb";
$i-view_column: "\e8ec";
$i-view_day: "\e8ed";
$i-view_headline: "\e8ee";
$i-view_list: "\e8ef";
$i-view_module: "\e8f0";
$i-view_quilt: "\e8f1";
$i-view_stream: "\e8f2";
$i-view_week: "\e8f3";
$i-visibility: "\e8f4";
$i-visibility_off: "\e8f5";
$i-card_membership: "\e8f7";
$i-card_travel: "\e8f8";
$i-work: "\e8f9";
$i-youtube_searched_for: "\e8fa";
$i-eject: "\e8fb";
$i-camera_enhance: "\e8fc";
$i-help_outline: "\e8fd";
$i-reorder: "\e8fe";
$i-zoom_in: "\e8ff";
$i-angle-right: "\e902";
$i-android1: "\e903";
$i-apple: "\e904";
$i-edit: "\e905";
$i-factory: "\e906";
$i-id_card: "\e907";
$i-licence: "\e908";
$i-phone_ring: "\e909";
$i-sign: "\e90a";
$i-task: "\e90b";
$i-user: "\e939";
$i-all_out: "\e90c";
$i-copyright: "\e90d";
$i-event_seat: "\e90e";
$i-fingerprint: "\e90f";
$i-flight_land: "\e910";
$i-flight_takeoff: "\e911";
$i-gavel: "\e912";
$i-gif: "\e913";
$i-goat: "\e914";
$i-accessible: "\e915";
$i-compare_arrows: "\e916";
$i-date_range: "\e917";
$i-donut_large: "\e918";
$i-donut_small: "\e919";
$i-http: "\e91a";
$i-important_devices: "\e91b";
$i-indeterminate_check_box: "\e91c";
$i-lightbulb_outline: "\e91d";
$i-line_style: "\e91e";
$i-line_weight: "\e91f";
$i-motorcycle: "\e920";
$i-offline_pin: "\e921";
$i-opacity: "\e922";
$i-pets: "\e923";
$i-picture_in_picture_alt: "\e924";
$i-play_for_work: "\e925";
$i-pan_tool: "\e926";
$i-euro_symbol: "\e927";
$i-g_translate: "\e928";
$i-pregnant_woman: "\e929";
$i-record_voice_over: "\e92a";
$i-remove_shopping_cart: "\e92b";
$i-delete_forever: "\e92c";
$i-restore_page: "\e92d";
$i-rounded_corner: "\e92e";
$i-rowing: "\e92f";
$i-speaker_notes_off: "\e930";
$i-timeline: "\e931";
$i-touch_app: "\e932";
$i-update: "\e933";
$i-watch_later: "\e934";
$i-zoom_out: "\e935";
$i-angle-down: "\e936";
$i-angle-left: "\e937";
$i-angle-up: "\e938";
$i-ac_unit: "\eb3b";
$i-airport_shuttle: "\eb3c";
$i-all_inclusive: "\eb3d";
$i-beach_access: "\eb3e";
$i-business_center: "\eb3f";
$i-casino: "\eb40";
$i-child_care: "\eb41";
$i-child_friendly: "\eb42";
$i-fitness_center: "\eb43";
$i-free_breakfast: "\eb44";
$i-golf_course: "\eb45";
$i-hot_tub: "\eb46";
$i-kitchen: "\eb47";
$i-pool: "\eb48";
$i-room_service: "\eb49";
$i-smoke_free: "\eb4a";
$i-smoking_rooms: "\eb4b";
$i-spa: "\eb4c";
$i-twet: "\e93c";
$i-linkdin: "\e93d";
$i-fb: "\e93e";
$i-check: "\e5ca";
$i-chat_bubble_outline1: "\e0cc";

