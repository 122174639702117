.materialTabs,
.matTabs {
	.nav-tabs {
		padding: 0px;
		overflow: auto;
		overflow-x: auto;
		background: #fff;
		overflow-y: hidden;
		border: none transparent;
		@include box-shadow("0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)");
		@extend %flex;
		& > li {
			margin: 0px;
			padding: 0px;
			font-size: 15px;
			background: none;
			border-radius: 0px;
			border: none transparent;
			text-transform: uppercase;
			@extend .cell !optional;
			& > a {
				width: 100%;
				display: block;
				font-size: 15px;
				border-radius: 0;
                font-weight: 600;
				text-align: center;
                @include font($fw-m);
                padding: 14px 15px 11px;
				border: none transparent;
				color: map-get($theme, text);
				border-bottom: transparent 3px solid;
				&:hover, &:focus {
					color: $prColor;
					border: none transparent;
					background: transparent none;
				}
				&:focus {
					background: rgba($prColor, 0.1);
					border-bottom: rgba($prColor, 0.05) 3px solid;
				}
			}
			&.active{
				border: none transparent;
				& > a {
					color: $prColor;
					border: none transparent;
					border-bottom: $prColor 3px solid;
					&:hover,&:focus{
						color: $prColor;
						border: none transparent;
						border-bottom: $prColor 3px solid;
					}
				}
			}
		}
	}
	.tab-pane{
		padding: 20px 15px;
		&.cardContainer {
			background: rgba(#000, 0.12);
		}
	}
	&.matRipple {
		.nav-tabs {
			& > li {
				& > a {
					position: relative;
					&:before {
						left: 0;
						right: 0;
						width: 0;
						bottom: 0;
						height: 2px;
						content: '';
						margin: 0 auto;
						position: absolute;
						background: transparent;
						@extend %animateFast;
					}
				}
				&.active {
					& > a {
						&:before {
							width: 100%;
							height: 2px;
							background: map-get($theme, color);
						}
					}
				}
			}
		}
	}
}