.searchService {
  min-height: rem(100px);
  color: map-get($theme, scWrite);
  background: map-get($theme, color);




  
  @media only screen and (max-width: 991px){
    padding-bottom: rem(10px); 
  }
  .form {
    padding: rem(27px) rem(10px);
    background: map-get($theme, colorL);
  }
  .h2{
    padding-top:  rem(35px);
    vertical-align: middle;

    @media only screen and (max-width: 767px){
      padding-top:  rem(15px);
      padding-bottom: rem(10px); 
    }
    @media only screen and (max-width: 1091px){
       font-size: 1em; 
    
    }

  }
 

 
  

select option {
  color: map-get($theme, text);
}
}
.cdn_f1{
  width: 100px;
  font-size: 1.2em;
  padding: rem(15px);
  vertical-align: middle;
  display: inline-block;
  @media only screen and (max-width: 767px){
    font-size: .8em;
   
  }
}

.cdn_f2{
  width: calc(100% - 110px);
}

.cdn_e1{
  width: 140px;
  font-size: 1.2em;
  padding: rem(15px);
  vertical-align: middle;
  display: inline-block;
  @media only screen and (max-width: 767px){
    font-size: .8em;
   
  }
}

.cdn_e2{
  width: calc(100% - 150px);
}

.btn_go{
  padding: rem(8px);
  color: map-get($theme, header-text);
  vertical-align: middle;
  padding-left: rem(20px);
  padding-right: rem(20px);
  background-color: map-get($theme, bg);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 1067px){
    margin-left: 5%;
    padding: rem(4px);
  }
  i{
    font-size: 2em;
    margin-top: 0px;
  }
}
