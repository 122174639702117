@import './utilities/breakpoints';
@import './utilities/display';
@import './utilities/flex';

@mixin ellipsis { overflow:hidden; white-space:nowrap; text-overflow: ellipsis; }

%ellipsis { @include ellipsis; }

@mixin pseudo{ position:absolute; content:''; }
%pseudo { @include pseudo; }

%animateXSlow {
	@include prefix((
		transition:all ease-in 1.3s
	), moz ms webkit );
}
%animateSlow {
	@include prefix((
		transition:all ease-in 1s
	), moz ms webkit );
}
%animateNormal {
	@include prefix((
		transition:all ease-in 0.6s
	), moz ms webkit );
}
%animateFast {
	@include prefix((
		transition:all ease-in 0.3s
	), moz ms webkit );
}
%animateXfast {
	@include prefix((
		transition:all ease-in 0.1s
	), moz ms webkit );
}

@include keyframes(spin) {
	0%{
		transform:rotate(0deg);transform:rotate(0deg)
	}
	100%{
		transform:rotate(359deg);transform:rotate(359deg)
	}
}

@mixin nilSelect{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
%nilSelect{ @include nilSelect; }

@mixin notaList{ list-style:none; margin:0; padding:0; }
%notaList { @include notaList; }

%clearfix { @include clearfix(); }

%inlineSpaceFix {
	font-size: 0;
	white-space: nowrap;
}

@mixin ghostVcenter {
    & {
        text-align: left;
        &:before {
            width: 1px;
            width: .1px;
            content: ' ';
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
        .vAlign {
            display: inline-block;
            vertical-align: middle;
            max-width: calc(100% - 0.5em);
        }
    }
}

.ghostVcenter,
%ghostVcenter {
    @include ghostVcenter;
}

.scroll,
.scrollX,
.scrollY {
    overflow: scroll;
    overflow: auto;
}

.scrollX,
%scrollX {
    overflow-x: auto;
    overflow-y: hidden;
}

.scrollY,
%scrollY {
    overflow-y: auto;
    overflow-x: hidden;
}

%cropped,
.cropped {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    min-height: rem(200px);
    & > a {
        top: 0;
        #{$left}: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    img {
        top: 50%;
        left: 50%;
        // max-width: initial;
        position: absolute;
        pointer-events: none;
        @include prefix((
            transform: translateY(-50%) translateX(-50%)
        ), webkt ms );
    }
}
