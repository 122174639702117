input[type="radio"],
input[type="checkbox"] {
    $obG: 6px;
    $obW: 22px;
    $obH: 22px;

    &.custom {
        padding: 0;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0 none;
        overflow: hidden;
        position: absolute;
        clip: rect(0 0 0 0);

        & + label {
            cursor: pointer;
            min-height: $obH;
            line-height: $obH;
            margin: 0 8px 0 0;
            position: relative;
            font-weight: normal;
            word-wrap: break-all;
            word-wrap: break-word;
            display: inline-block;
            vertical-align: middle;
            font-size: $baseFontSize;
            padding-left: $obW + $obG;
            // background-position: 0 0;
            // background-repeat: no-repeat;

            @extend %nilSelect;

            &:after,
            &:before {
                top: 0px;
                left: 0px;
                content: ' ';
                position: absolute;
            }

            &:after {
                top: -$obH / 2;
                left: -$obW / 2;
                width: $obW * 2;
                height: $obH * 2;
                background: rgba(#000, 0.2);
                transition-duration: 0.2s;
                -webkit-transition-duration: 0.2s;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

                border-radius: 50%;
                @include prefix((transform: scale(0)), webkit ms);
            }

            &:before {
                min-width: $obW;
                min-height: $obH;
                font-size: $obW;
                line-height: $obH;
                font-family: $icomoon-font-family;
                // background-repeat: no-repeat;
                // background-image: url("#{$img}/chkbox-mtl.png");
                content: $i-check_box_outline_blank;
            }
        }

        &:checked + label:before {
            color: map-get($theme, color);
            // background-position: 0px -#{$obH};
            content: $i-check_box;
        }

        &:focus {
            & + label {
                &:after {
                    background: rgba(#000, 0.2);

                    @include prefix((transform: scale(1)), webkit ms);
                }
            }

            &:checked + label:after {
                background: rgba($prColor, 0.2);
            }
        }

        &.disabled,
        &[disabled],
        &:disabled {
            & + label:before {
                opacity: 0.5;
                // background-position: 0px #{-$obH * 2};
            }

            &:checked {
                & + label:before {
                    opacity: 0.5;
                    // background-position: 0px #{-$obH * 3};
                }
            }
        }

        &.error {
            & + label:before {
                color: map-get($matColor, danger);
                // background-position: 0px #{-$obH * 4};
            }

            // &:checked + label:before {
            //     background-position: 0px -#{$obH};
            // }
        }

        // &.switch {
        //     margin: 0 5px;

        //     & + label {
        //         height: 30px;
        //         margin: 0 2px;
        //         text-indent: 0;
        //         min-width: 36px;
        //         background: none;
        //         line-height: 30px;
        //         position: relative;
        //         padding: 0 0 0 42px;

        //         &:after,
        //         &:before {
        //             top: 50%;

        //             @extend %animateFast;
        //         }

        //         &:before {
        //             left: 0;
        //             width: 36px;
        //             height: 12px;
        //             overflow: hidden;
        //             margin-top: -6px;
        //             background: rgba(#000, 0.38);

        //             border-radius: 20px;
        //         }

        //         &:after {
        //             left: 0px;
        //             width: 20px;
        //             height: 20px;
        //             content: ' ';
        //             background: #fff;
        //             position: absolute;
        //             margin: -10px 0 0 -1px;

        //             border-radius: 50%;
        //             @include box-shadow("0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)");
        //         }
        //     }

        //     &:checked + label {
        //         &:before {
        //             background: rgba($prColor, 0.38);
        //         }

        //         &:after {
        //             left: 16px;
        //             margin-left: 0px;
        //             margin-right: -1px;
        //             background: $prColor;
        //         }
        //     }
        // }
    }
}

input[type="radio"] {
    &.custom:not(.checkbox) {
        & + label:before {
            // border-radius: 50%;
            content: $i-radio_button_unchecked;
            // background-image: url("#{$img}/radio-mtl.png");
        }

        &:checked {
            & + label:before {
                // background-color: $scColor;
                content: $i-radio_button_checked;
            }
        }
    }

    // &.chkBtn {
    //     & + label:before {
    //         background-image: none;
    //     }
    // }
}

.chkGroup {
    font-size: 0;

    input[type="radio"],
    input[type="checkbox"] {
        &.chkBtn {
            & + label {
                margin: 0;
                font-size: 1rem;
                border-radius: 0;
                border-left-width: 0;
            }

            &:checked {
                & + label {
                    pointer-events: none;
                    border-color: transparent;

                    &:before {
                        background: none;
                    }
                }
            }

            &:first-child {
                & + label {
                    border-left-width: 1px;
                }
            }

            &:last-child {
                & + label {
                    border-left-width: 1px;
                }
            }
        }
    }
}

.radio label,
.checkbox label {
    margin-bottom: 0;
    min-height: 20px;
    padding-left: 20px;
}

label.checkbox,
label.radio {
    $obG: 10px;
    $obW: 22px;
    $obH: 22px;

    cursor: pointer;
    min-width: $obW;
    min-height: $obH;
    line-height: $obH;
    font-weight: normal;
    margin: 0 10px 5px 0;
    display: inline-block;
    vertical-align: middle;
    background-position: 0 0;

    // font-size: rem(18px);
    // padding: 0 0 0 #{$obH + 10} !important;
    /*nilSelect*/

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & + & {
        margin-top: 0;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
    }

    &:after {
        top: -$obH / 2;
        left: -$obW / 2;
        width: $obW * 2;
        height: $obH * 2;
        background: rgba(#000, 0.2);
        transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

        border-radius: 50%;
        @include prefix((transform: scale(0)), webkit ms);
    }

    // &:before {
    //     width: $obW;
    //     height: $obH;
    //     margin-right: 5px;
    //     position: relative;
    //     display: inline-block;
    //     background-repeat: no-repeat;
    //     background-image: url("#{$img}/chkbox-mtl.png");
    // }

    &:before {
        min-width: $obW;
        min-height: $obH;
        font-size: $obW;
        line-height: $obH;
        font-family: $icomoon-font-family;
        content: $i-check_box_outline_blank;
    }

    label {
        margin: 0;
        padding: 0px;
        min-height: $obH;
        vertical-align: top;
        display: inline-block;
    }

    input[type=radio],
    input[type=checkbox] {
        border: 0;
        padding: 0;
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        position: absolute;
        clip: rect(0 0 0 0);
    }

    &.checked:before {
        content: $i-check_box;
        color: map-get($theme, color);
    }

    &.disabled {
        &:before {
            opacity: 0.5;
        }

        &.checked {
            &:before {
                opacity: 0.5;
            }
        }
    }

    // &.focus { outline: 1px dotted; }

    &.focus,
    &:focus {
        &:after {
            background: rgba(#000, 0.2);

            @include prefix((transform: scale(1)), webkit ms);
        }

        &.checked:after {
            background: rgba($prColor, 0.2);
        }
    }

    &.switch {
        height: 30px;
        margin: 0 2px;
        text-indent: 0;
        min-width: 36px;
        background: none;
        line-height: 30px;
        position: relative;
        padding: 0 0 0 45px !important;

        &:after,
        &:before {
            top: 50%;
            content: '';
            position: absolute;

            @extend %animateFast;
        }

        &:before {
            left: 0;
            width: 36px;
            height: 12px;
            overflow: hidden;
            margin-top: -6px;
            background: rgba(#000, 0.38);

            border-radius: 20px;
        }

        &:after {
            left: 0px;
            width: 20px;
            height: 20px;
            content: ' ';
            background: #fff;
            margin: -10px 0 0 -1px;

            border-radius: 50%;
            @include box-shadow("0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)");
        }

        &.checked {
            &:before {
                background: rgba($prColor, 0.38);
            }

            &:after {
                left: 16px;
                margin-left: 0px;
                margin-right: -1px;
                background: $prColor;
            }
        }
    }
}

label.radio {
    &:before {
        // border-radius: 50%;
        content: $i-radio_button_unchecked;
        // background-image: url("#{$img}/radio-mtl.png");
    }

    &.checked {
        &:before {
            // background-color: $scColor;
            content: $i-radio_button_checked;
        }
    }
}

.rating-select {
    direction: rtl;
    margin: 0px 5px;
    cursor: pointer;
    display: inline-block;
    unicode-bidi: bidi-override;

    // input.custom[type="checkbox"],
    input.custom[type="radio"] {
        & + label {
            min-height: 1em;
            margin: 0 -1px 0;
            line-height: 1em;
            padding-left: 1em;
            font-size: inherit;

            &:before {
                width: 1em;
                height: 1em;
                line-height: 1em;
                content: $i-star;
                background: none;
                font-family: "#{$icomoon-font-family}";
                color: rgba(map-get($theme, text), 0.4);
                text-shadow: 0px 0px 4px rgba(#000, 0.16);

                // background-image: url('#{$img}/rating-mtl.png');
           
            }

            &:after {
                left: 42%;
                top: -18px;
                opacity: 0;
                width: auto;
                height: 18px;
                color: #fff;
                font-size: 12px;
                padding: 0 10px;
                min-width: 18px;
                line-height: 1.6em;
                position: absolute;
                white-space: nowrap;
                content: attr(aria-rating);
                background: rgba(#333, 0.8);

                @include prefix((transform: translate(-50%, 0%)), ms webkit moz);
                border-radius: 10px;
            }

            &[aria-rating]:hover {
                &:after {
                    opacity: 1;
                }
            }
        }

        &:checked {
            & + label {
                &:before {
                    background: transparent;
                    color: map-get($theme, color);
                }

                &[aria-rating] {
                    &:hover {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            & ~ label {
                position: relative;

                &:before {
                    // background-position: 0px 25%;
                    color: gold;
                    content: $i-star;
                }
            }
        }

        &[disabled] + label[aria-rating],
        &:disabled + label[aria-rating] {
            &:after {
                display: none;
            }
        }
    }

    &[readonly],
    &.readonly {
        pointer-events: none;

        input.custom[type="radio"] + label {
            pointer-events: none;
        }
    }

    &[disabled],
    &.disabled {
        pointer-events: none;

        // input.custom[type="checkbox"],
        input.custom[type="radio"] {
            & + label {
                pointer-events: none;

                &:before {
                    background-position: 0px 50%;
                }

                &[aria-rating]:after {
                    display: none;
                }
            }

            &:checked {
                & + label[aria-rating] {
                    &:after {
                        opacity: 1;
                    }
                }

                & ~ label {
                    position: relative;

                    &:before {
                        background-position: 0px 25%;
                    }
                }
            }
        }
    }
}

.rating {
    font-size: 0;
    direction: rtl;
    display: inline-block;
    unicode-bidi: bidi-override;
    span.star {
        color: #777777;
        font-style: normal;
        font-weight: normal;
        font-size: rem(16px);
        display: inline-block;
        &:before {
            content: $i-star;
            font-family: "#{$icomoon-font-family}";
        }
        &.active {
            &, & ~ span.star {
                color: gold;
            }
        }

        &.interactive:hover {
            span.star {
                cursor: pointer;
                color: #777777;
            }
            &, & ~ span.star {
                color: gold;
            }
        }
    }
}

.mandatory:after {
    color: #e00;
    content: ' *';
    line-height: 0;
    @include font($prFont);
}

// BUTTON
.btn {
    font-size: 1em;
    text-decoration: none;
    border-radius: rem($matBorderRadius);
    
    @include font($fw-m);
    @extend %animateFast;

    // &.shadowBtn {
    //     border: 0 none transparent;
    //     @include box-shadow("0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)");
    // }

    &-mtl {
        color: #000;
        border-color: transparent;
        text-transform: uppercase;
        border-radius: rem($matBorderRadius);
        @include font($fw-m);

        &:hover, &:focus, &:active {
            border-color: transparent;
            background: lighten(#000, 90%);
        }

        &,
        &.btn-theme,
        &.btn-primary,
        &.btn-info,
        &.btn-success,
        &.btn-warning,
        &.btn-danger,
        &.btn-secondary {
            color: #fff;

            @include box-shadow("0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)");
        }

        &.btn-light,
        &.btn-default {
            color: rgba(#000, 0.87);
        }

        &.btn-link {
            color: #000;
            @include font($fw-m);
            text-transform: initial;
            background: transparent;
            border-color: transparent;

            @include box-shadow("none");

            &:hover, &:focus, &:active {
                text-decoration: none;
                background: rgba(#000, 0.16);
            }
        }

        @each $color in $colorType {
            &.btn-#{nth($color, 1)} {
                background: nth($color, 2);
                &:hover, &:focus, &:active {
                    background: darken(nth($color, 2), 6.5%);
                }

                &.btn-link {
                    color: nth($color, 2);
                    background: transparent;

                    &:hover, &:focus, &:active {
                        background: rgba(nth($color, 2), 0.16);
                    }
                }
            }
        }
    }

    &-o {
        &.btn-info,
        &.btn-accent,
        &.btn-default,
        &.btn-primary,
        &.btn-warning,
        &.btn-success,
        &.btn-danger {
            background: none;
        }

        &.btn-default {
            color: $btn-default-bg;
            border-color: $btn-default-bg;

            &:hover, &:focus, &:active {
                color: #fff;
                background: rgba($btn-default-bg, 0.16);
            }
        }

        &.btn-primary {
            color: $btn-primary-bg;
            border-color: $btn-primary-bg;

            &:hover, &:focus, &:active {
                color: #fff;
                background: $btn-primary-bg;
            }
        }

        &.btn-info {
            color: $btn-info-bg;
            border-color: $btn-info-bg;

            &:hover, &:focus, &:active {
                color: #fff;
                background: $btn-info-bg;
            }
        }

        &.btn-warning {
            color: $btn-warning-bg;
            border-color: $btn-warning-bg;

            &:hover, &:focus, &:active {
                color: #fff;
                background: $btn-warning-bg;
            }
        }

        &.btn-success {
            color: $btn-success-bg;
            border-color: $btn-success-bg;

            &:hover, &:focus, &:active {
                color: #fff;
                background: $btn-success-bg;
            }
        }

        &.btn-danger {
            color: $btn-danger-bg;
            border-color: $btn-danger-bg;

            &:hover, &:focus, &:active {
                color: #fff;
                background: $btn-danger-bg;
            }
        }

        &.btn-accent {
            color: map-get($color, accent);
            border-color: map-get($color, accent);

            &:hover, &:focus, &:active {
                color: #fff;
                background: darken(map-get($color, accent), 6.5%);
            }
        }
    }
}

// Blur
[data-has-filter="true"] {
    overflow: hidden;
    position: relative;
    text-align: center;
    & > *:not([data-filter]) {
        z-index: 1;
        position: relative;
	}
	& > img {
		top: 50%;
        left: 50%;
        // max-width: initial;
        pointer-events: none;
        position: absolute !important;
        @include prefix((
            transform: translateY(-50%) translateX(-50%)
        ), webkt ms );
	}
    [data-filter] {
        opacity: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        background-size: cover;
        background-position: center;
    }
    [data-filter="blur"] { opacity: 0.5; }
}

[data-has-filter="false"] {
	[data-filter] { opacity: 0; }
}

[data-filter="blur"] {
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
    opacity: 0.4;
    filter: url('#blur');
    -webkit-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
    background-size: 100% 100%;
}