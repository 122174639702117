@import "../plugins/animate";

.animated {
    &._fast {
        @include prefix((animation-duration: 0.21s), webkit ms);
    }

    &._1s {
        @include prefix((animation-duration: 1s), webkit ms);
    }

    &._1-5s {
        @include prefix((animation-duration: 1.5s), webkit ms);
    }

    &._2s {
        @include prefix((animation-duration: 2.1s), webkit ms);
    }

    &._2-5s {
        @include prefix((animation-duration: 2.5s), webkit ms);
    }

    &.loop {
        @include prefix((animation-iteration-count: infinite), webkit ms);
    }
}

.loader {
    width: rem(60px);
    height: rem(60px);
    border-radius: 50%;
    border-style: solid;
    border-width: rem(5px);
    border-color: rgba(map-get($theme, scColor), 0.3);
    border-top-color: map-get($theme, scColor) !important;
    animation: spin 2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

// @include keyframes(pulsate) {
//     0% {
//         -webkit-transform: scale(1, 1);
//         transform: scale(1, 1);
//         opacity: 0.0;
//     }
//     50% {
//         opacity: 1.0;
//     }
//     100% {
//         -webkit-transform: scale(2.2, 2.2);
//         transform: scale(2.2, 2.2);
//         opacity: 0.0;
//     }
// }
