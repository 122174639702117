// Global Material Controls Class Defenision
.mtlAnimation, %mtlAnimation {
	transition-duration: 0.2s;
	-webkit-transition-duration: 0.2s;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mtl {
	$root: &;
	margin-bottom: 0;
	position: relative;
	font-size: em(14px);
	padding: $ctrlpadding 0;
	::-webkit-input-placeholder {
		color: $placeholderColor;
	}
	:-ms-input-placeholder {
		color: $placeholderColor;
	}
	::-moz-placeholder {
		color: $placeholderColor;
		opacity: 1;
	}
	
	.txtView,
	textarea, select,
	label[data-control],
	input:not([type="checkbox"]):not([type="radio"]) {
		margin: 0;
		padding: 0;
		width: 100%;
		border: 0 none;
		font-size: 1em;
		display: block;
		min-width: auto;
		background: 0 0;
		height: rem(32px);
		line-height: rem(32px);
		outline: none transparent !important;
		@include box-shadow(none);
		&[disabled] {
			background: transparent;
			border-bottom-style: dotted;
			border-bottom-color: rgba(#000, 0.26); //#AFB1B3;
		}
	}

	textarea {
		min-width: 100%;
		max-width: 100%;
		min-height: 4.5em;
	}
	
	small, .message {
		bottom: 0;
		width: 100%;
		#{$left}: 0px;
		#{$right}: 0px;
		max-height: 1.6em;
		line-height: 1.4em;
		position: absolute;
		pointer-events: none;
	}

	.error{
		margin: 0;
		color: $errorColor;
	}

	&[data-type="group"],
	&[data-type="search"] {
		height: auto;
		.btn, button,
		.rSect, .lSect {
			top: 0;
			bottom: 0;
			margin: 0;
			width: 2.4em;
			margin: auto;
			#{$right}: 0px;
			color: inherit;
			height: rem(32px);
			text-align: center;
			position: absolute;
			padding: 0 rem(6px);
			border: none transparent;
			background-color: transparent;
		}
		.rSect {
			padding-left: 5px;
		}
		.lSect {
			#{$left}: 0;
			#{$right}: auto;
			padding-right: 5px;
		}
		&.x_{
			&o-- {
				.txtView,
				textarea, select,
				label[data-control],
				input:not([type="checkbox"]):not([type="radio"]){
					padding-left: 32px;
				}
			}
			&--o {
				.txtView,
				textarea, select,
				label[data-control],
				input:not([type="checkbox"]):not([type="radio"]){
					padding-right: 32px;
				}
			}
			&o-o {
				.txtView,
				textarea, select,
				label[data-control],
				input:not([type="checkbox"]):not([type="radio"]){
					padding-left: 32px;
					padding-right: 32px;
				}
			}
		}
		&.mtl-no-label  {
			.btn, button,
			.rSect, .lSect { margin-top: 0; }
		}
	}
	
	&-hidden {
		display: none;
	}
	&-text {
	}
	&-select {
		overflow: hidden;
		// background: #fff;
		&:before {
			top: -1px;
			z-index: 2;
			width: 32px;
			bottom: 0px;
			height: 33px;
			margin: auto 0;
			#{$right}: 0px;
			line-height: 32px;
			background: #fff;
			position: absolute;
			text-align: center;
			pointer-events: none;
			content: $i-keyboard_arrow_down;
			font-family: '#{$icomoon-font-family}';
		}
		select {
			border-radius: 0;
			width: calc(100% + 20px);
			background: none transparent;
			&::-moz-focus-inner { border: none; }
			&:-moz-focusring { color: transparent; text-shadow: 0px 0px 0px #000; }
		}
		&.mtl-no-label:before {
			margin-top: 0;
		}
		&:not(.hasValue):not(.mtl-no-label){
			&:before { height: 31px; }
			.mtl-label {
				z-index: 0;
				background: #fff;
			}
		}
	}
	&-button {
	}
	&-label {
		top: 0px;
		#{$left}: 0px;
		z-index: 2;
		bottom: 0px;
		width: 100%;
		margin: 0px;
		padding: 0px;
		color: #333;
		font-size: 80%;
		overflow: hidden;
		position: absolute;
		line-height: 1.2em;
		white-space: nowrap;
		pointer-events: none;
		@extend %mtlAnimation;
		@include font($matLblFont);
		&:after,
		&:before {
			width: 0%;
			z-index: 0;
			height: 2px;
			content: ' ';
			#{$left}: 0px;
			#{$right}: 0px;
			margin: 0 auto;
			position: absolute;
			bottom: $ctrlpadding;
			background: $labelFocus;
			@extend %mtlAnimation;
		}
		&:after {
			height: 1px;
			width: 100%;
			background: rgba(#000, 0.12);
		}
		&:before { z-index: 1; }
		
		small, .message {
			bottom: 0;
			opacity: 0;
			font-size: 14px;
			position: absolute;
			pointer-events: none;
			color: rgba(#000, 0.64);
		}
	}

	&.mtl-no-label  {
		padding-top: 0px;
		.btn, button,
		.rSect, .lSect { margin-top: 0; }
		.mtl-select:before {
			margin-top: 0;
			margin-bottom: auto;
		}
		&.focus {
			#{$root}-label { pointer-events: none; }
		}
	}
	&.mtl-no-message {
		padding-bottom: 0;
		&:after,
		&:before {
			bottom: 0;
		}
		#{$root}-label {
			&:before, &:after {
				bottom: 0px;
			}
		}
		&.mtl-select:before {
			margin-top: auto;
			margin-bottom: 0;
		}
		small { display: none; }
		&:before, &:after { bottom: 0px; }
	}

	&.mtl-no-label.mtl-no-message {
		&.mtl-select:before {
			margin: 0;
		}
	}
	
	&.hasMessage {
		#{$root}-label {
			small, .message {
				opacity: 1;
			}
		}
	}

	// &.onError,
	// &.disabled,
	// &.onSuccess,
	&.hasValue,
	&.focus {
		#{$root}-label {
			top: 0px;
			#{$left}: 0px;
			font-size: 80%;
			color: rgba(#000, 0.64);
			& > i {
				font-size: 110%;
				color: $errorColor;
			}
		}
	}

	// &.onError,
	// &.disabled,
	// &.onSuccess,
	&.hasValue,
	&.focus {
		#{$root}-label {
			@include font($matLblFontFocus);
		}
	}

	// &.onError,
	// &.disabled,
	// &.hasValue,
	// &.onSuccess,
	&.focus {
		#{$root}-label{
			color: $labelFocus;
			@include font($matLblFontFocus);
		}
	}
	
	&.onError,
	&.onSuccess {
		& > small, & > .message { opacity: 0; }
	}
	
	&.focus,
	&.onError,
	&.disabled {
		#{$root}-label {
			&:before { width: 100%; }
			&:after { background: transparent !important; }
		}
	}

	&.onError {
		#{$root}-label {
			&:before,
			&:after { background: $errorColor; }
			small, .message { color: $errorColor; }
		}
	}
	&.onSuccess {
		#{$root}-label {
			&:before,
			&:after { background: $successColor; }
			small, .message { color: $successColor; }
		}
	}
	&.theme {
		#{$root}-label {
			&:before,
			&:after { background: $prColor; }
			small, .message { color: $prColor; }
		}
	}
	&.scTheme {
		#{$root}-label {
			&:before,
			&:after { background: $scColor; }
			small, .message { color: $scColor; }
		}
	}
	&.info {
		#{$root}-label {
			&:before,
			&:after { background: $brand-info; }
			small, .message { color: $brand-info; }
		}
	}
	&.warning {
		#{$root}-label {
			&:before,
			&:after { background: $brand-warning; }
			small, .message { color: $brand-warning; }
		}
	}
	&.disabled {
		#{$root}-label {
			&:before {
				background: transparent;
				background-size: 4px 1px;
				background-repeat: repeat-x;
				background-image: -webkit-linear-gradient(right,rgba(#000,.26) 0,rgba(#000,.26) 33%,transparent 0);
				background-image: linear-gradient(to right,rgba(#000,.26) 0,rgba(#000,.26) 33%,transparent 0);
			}
		}
	}
	&-floatingLabel {
		#{$root}-label {
			font-size: 100%;
			color: $placeholderColor;
			top: $ctrlpadding + em(5px);
			&.onError, &.onSuccess, &.focus {
				#{$root}-label {
					// top: 0px;
					// left: 0px;
					// font-size: 80%;
					color: $labelFocus;
				}
			}
		}
		&[data-type="group"],
		&[data-type="search"] {
			height: auto;
			.btn, button,
			.rSect, .lSect {
				top: $ctrlpadding;
			}
		}
	}
}