::-moz-selection {
    background-color: rgba($prColor, 0.3);
}

::selection {
    background-color: rgba($prColor, 0.3);
}

* {
    &:before, &:after {
        pointer-events: none;
    }
}

html,
body {
    width: 100%;
    height: 100%;
    font-size: $baseFontSize;
    @if variable-exists(minWidth) {
        min-width: $minWidth;
    }
    @if variable-exists(maxWidth) {
        max-width: $maxWidth;
    } // overflow: hidden;
}

body {
    font-weight: 400; // line-height: em(33px);
    color: map-get($theme, text);
    background: map-get($theme, bg);
    font-family: map-get($theme, fontFamily);
    overflow: scroll; // /* has to be scroll, not auto
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; // background: map-get($theme, bg);
    // font-style: normal;
    // font-kerning: normal;
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    // -webkit-backface-visibility: hidden;
    &.onLoading {
        &:after,
		&:before {
            content: '';
            position: fixed;
        }
		&:before {
            z-index: 999;
            pointer-events: all;
			background-color: rgba(#ccc, 0.8) !important;
			@extend .absFC;
        }
        &:after {
            top: 50%;
            left: 50%;
            z-index: 1000;
            @extend .loader;
            border-color: rgba(#000, 0.3);
            border-top-color: map-get($theme, scColor);
        }
		&[data-loader-message]:after {
			left: 0;
			right: 0;
			top: 50%;
			margin: 0 auto;
			display: block;
			color: $prColor;
			z-index: 10000000;
			background: #fff;
			position: absolute;
			text-align: center;
			margin-top: rem(25px);
			content: attr(data-loader-message);
		}
	}
}

a,
button {
    &>* {
        pointer-events: none;
    }
}

a {
    color: $prLink;
    &,
    &>* {
        outline: none;
    }
    &>* {
        pointer-events: none;
    }
    &,
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
        text-decoration: none;
    }
    &:hover,
    &:active,
    &:focus,
    &.active {
        color: map-get($theme, color);
    }
}

b {
    font-weight: normal;
    @include font(map-get($theme, fontB));
}

svg {
    &.defs {
        width: 0;
        height: 0;
        position: absolute;
    }
}

fieldset {
    padding: rem(7px) rem(20px);
    border-radius: rem($brdRad);
    border: rgba(#000, 0.36) rem(1px) solid;
}

.container-fluid {
    &.pageWrapper {
        padding: 0;
        margin: 0 auto;
        max-width: 1200px;
        @include box-shadow(0 0 rem(15px) rgba(0, 0, 0, 0.5));
        // max-width: calc(100% - 60px);
        // @media only screen and (min-width: 1100px) {
        // 	max-width: calc(100% - 40px);
        // }
    }
}

// :not(input):not(textarea) {
//     @extend .nilSelect !optional;
// }

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    padding: 0px;
    line-height: 1.2em;
    font-weight: normal;
}

h1,
.h1 {
    font-size: em(40px);
}

h2,
.h2 {
    font-size: em(30px);
}

h3,
.h3 {
    font-size: em(24px);
}

h4,
.h4 {
    font-size: em(20px);
}

h5,
.h5 {
    font-size: em(18px);
}

h6,
.h6 {
    font-size: em(16px);
}

img {
    max-width: 100%;
    height: auto !important;
}

.fw,
.fh {
    position: relative;
}

.fw {
    width: 100%;
}

.fh {
    height: 100%;
}

video.asBackground {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
}

.owl-nav button.owl-prev,
.owl-nav button.owl-next,
button.owl-dot {
    background: transparent;
    border: 0 none transparent;
}

@import 'utilities';

// Custom
@media only screen and (min-width: $screen-xl) {
    .container {
        width: $container-xl-desktop;
    }
}