.service_section{
    width: 100%;
    height: auto;
    padding-top: 14em;
    background-image: linear-gradient(#fff,#f2f1ec,#ede8d5, #ede8d5);
    @media only screen and (max-width: 767px){
      padding-top: 8em;
    }
  }
  .top_link_wrap{
    width: 100%;
    color: map-get($theme,brdr );
    margin-bottom: rem(13px);
    a{
      color: map-get($theme, brdr );
      font-size:  rem(14px);

    }
    i{
      padding-top: rem(6px);
      font-size:  rem(14px);
    }
  }
  .inp_ser_sear{
  min-width: 84%;
  padding:rem(7px);
  border-radius: 5px;
  margin-left: 2%;
  border: 1px;
  border-style: solid;
  border-color:  map-get($theme,brdr );
  margin-top:rem(6px);
  margin-right: 0.66667rem;
  @media only screen and (max-width: 1122px){
    min-width: 80%;

  }

  @media only screen and (max-width: 767px){
    margin-left: 0%;
    min-width: 70%;
  }
  }
  
  .bt_serch_ser{
    background-color: map-get($theme , accent );
    padding: rem(8px);
    color: map-get($theme , scWrite );
    min-width: 10%;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.3);
    float: right;
    margin-top:rem(4px);
    @media only screen and (max-width: 767px){
      float: right;
      min-width: 5%;
      padding: rem(5px);
      margin-top:rem(5px);
     
    }
  }
  .top_mn{
    margin-left: 1%;
    padding:rem(15px); 
  }
  .btn_clr{
    border: none;
    border-radius: 12px;
    padding: rem(2px) rem(15px) rem(2px) rem(15px);
    font-size: rem(14px);
    text-align: center;
    margin-left: 1%;
    background-color: map-get($theme,colorL );
    color: map-get($theme, scWrite )
  }
  .Ser_ind{
    width: 100%;
    display: table;

    @media only screen and (max-width: 467px){
      display: block;
      width: 100%;
      text-align: center;
    }
   
    .serv_img_wrap{
      width: 5%;
      height: auto;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      padding: 0px;
     
      @media only screen and (max-width: 467px){
        display: block;
        width: 100%;
        text-align: center;
      }

      img{
        width: rem(30px);
        
      }
    }
.serv_cdn_wrap{
  vertical-align: middle;
  display: table-cell;
  padding: 0 rem(10px);
  @media only screen and (max-width: 467px){
    display: block;
    width: 100%;
    text-align: center;
  }
  span{
    color: map-get($theme,accent );
    @include font($fontB);
/* @media only screen and (max-width: 1467px){
      font-size:  rem(12px);
    } */
    

    @media only screen and (max-width: 767px){
      
      font-weight: 400;
      }

  }

}
  .serv_btn_wrap{
    width: 30%;
    height: auto;
    vertical-align: middle;
    display: table-cell;

    
    @media only screen and (max-width: 1267px){
      width: 35%;
    }

    // @media only screen and (max-width: 767px){
    //     width: 75%;
    //   }
  } 
    
    .btn_view{
      border: none;
    border-radius: 14px;
    padding: rem(5px) rem(15px) rem(5px) rem(15px);
    font-size: rem(14px);
    text-align: center;
    margin-left: 1%;
    background-color: map-get($theme,colorL );
    color: map-get($theme, scWrite );
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
    float: right;
    margin-top: rem(4px);
    }  

    .btn_start_ser{
      border: none;
    border-radius: 14px;
    padding: rem(5px) rem(15px) rem(5px) rem(15px);
    font-size: rem(14px);
    text-align: center;
    margin-left: 2%;
    margin-right: 1%;
    background-color: map-get($theme,accent );
    color: map-get($theme, scWrite );
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
    float: right;
    margin-top: rem(4px);
    }  
  }
  .ser_left{
    padding-left: rem(20px);
    h4{
      @include font($fontB);
      padding-top: rem(10px);
      padding-bottom: rem(10px);

      .btn_clr{
        float: right;
        font-size: 14px;
        font-weight: 300;
      }

    }
    h6{
      padding: rem(10px);
      padding-left: rem(0px);
    }
  }
  #service_pagenation{
    text-align: center;
 
    li{
      a{
        margin:  rem(3px);
        padding: rem(10px) rem(10px) rem(10px) rem(10px);
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        border: none;
      }
    }
  }
  
.service-main{
  width: 100%;
  height: auto;
  padding-left: 20px;
  @media only screen and (max-width: 991px){
    padding-left: 0px;
  }
  

}
  
  .wd_cal_bk{
    width: 98%;
    margin-left: 1%;
    height: auto;
    float: left;
    margin-top: rem(20px);
    margin-bottom:  rem(50px);

    .get_calbk{
      min-height: 80px;
      background-color: #34314c;
      border-radius: 6px 0px 0px 6px;
      color: #fff;
      text-align: center;
      padding: 25px;
      font-style: italic;
      display: inline-block;
      vertical-align: middle;
      @media only screen and (max-width: 1167px){
        // float: none;
        border-radius: 0px  0px 0px  0px;
        width: 100%;
      }

      @media only screen and (max-width: 767px){
        border-radius: 0px  0px 0px  0px;
        width: 100%;
      }

    h3{
      @media only screen and (max-width: 1367px){
        font-size: rem(20px);
      }
    }

    }
    .get_calbk2{
      min-height: 80px;
      background-color: #524f67;
      border-radius: 0px 6px 6px 0px;
      padding: 15px;
      vertical-align: middle;
      display: inline-block;

      .inp_sear_btm{
        color: map-get($theme, scWrite );

       
      } 

      @media only screen and (max-width: 1167px){ 
        float: none;
        border-radius: 0px  0px 0px  0px;
        width: 100%;
      }

      @media only screen and (max-width: 767px){
        border-radius: 0px  0px 0px  0px;
        width: 100%;
      }
      img{
        width: 5%;
        margin-top: -5px;
        margin-left: 2%;
        margin-right:  2%;

        @media only screen and (max-width: 1167px){
          width: 5%;
          margin-left: 1%;
          margin-top: -3px;
          float: left;
        }

        @media only screen and (max-width: 767px){
          display: none;
        }
      }
      button{
        background-color: map-get($theme , bg );
       
         
        @media only screen and (max-width: 767px){
          width: 100%;
         
        }
      }
    }
  }
  
  .inp_sear_btm{
    
      min-width: 62%;
      padding: rem(8px);
      background-color: transparent;
      border: 1px;
      border-style: solid;
      border-color:map-get($theme, header-bg); 
      margin-top:rem(5px);
      margin-right: rem(10px);
      @media only screen and (max-width: 1467px){
        min-width: 57%;
      }
      @media only screen and (max-width: 1267px){
        min-width: 48%;
      }

      @media only screen and (max-width: 767px){
        min-width: 99.5%;
        float: left;
        margin-right: rem(1px);
        padding: rem(5px);
        margin-bottom: rem(5px);
      }
  }

  .est_cntr{
    padding-left: rem(25px);
     padding-right: rem(25px);
     border-right: 1px solid;
        border-color: map-get($theme , brdr );
        text-align: center;
        min-height: 270px;
     .top_padd_proccs{
       padding-top: rem(40px);
       float: left;
       width: 100%;
       color: map-get($theme, brdr);
     }

     .inp-ent-val{
       width: 15%;
       padding:  rem(3px);
       text-align: center;
       color: map-get($theme, accent);
     }
       .bt_ma_in{
         min-width:70% ;
         margin-top: 13px;
         
        @media only screen and (max-width: 1267px){
          min-width:30% ;
        }
       }
       .bt_ma_in2{
        min-width:40% ;
        margin-top: 13px;
       }   
       .txt_lft{
         text-align: left;
         padding-top:  rem(25px);
       }
        .pay_h5{
          font-weight: 600;
          padding-top: rem(45px);
          padding-bottom: rem(25px);
  
          b{
            color: map-get($theme , accent );
          }
          span{
            padding-left: rem(15px);
            padding-right: rem(15px);
            font-weight: 400;
            color:#2bad02;
          }
          i{
            border-radius: 50%;
            background-color:#2bad02;
            margin-right: rem(10px);
            color: map-get($theme, scWrite );
          }
          
        }
        .status_evl{
          padding: rem(5px);
          margin-top: 20px;
          border-radius: 15px;
          color:  #2bad02;
          border: 1px solid #2bad02;
          background-color: #2bad0261;
  
        }
  }
  .btn_note{
    margin-top: 100px;
    text-align: center;
    width: auto;
    float: left;
    @media only screen and (max-width: 1267px){
      margin-top: 0px;
    }
  span{
    width: 100%;
    float: left;
    text-align: center;
  }
    i{
      font-size: rem(36px);
      width: 100%;
    }
    
  }

  
.Addr_wrp{
  text-align: left;
  border: 1px solid;
  border-color: map-get($theme , brdr );
  padding:rem(15px);  
  h6{
    font-size:rem(15px); 
  }
}
 .est_left{
   padding-left: rem(25px);
   padding-right: rem(25px);
   border-right: 1px solid;
      border-color: map-get($theme , brdr );

  h4{
    font-weight: bold;
    padding-top: rem(10px);
  }
  h6{
    padding-top: rem(7px);
    padding-bottom: rem(7px);
    span{
      color: map-get($theme, brdr);
      padding-right: 5%;
      padding-top: rem(2px);
    }
    a{
      font-weight: bold;
      padding-left: rem(5px);
      float: right;
      cursor: default;
      &:hover {
        color: map-get($theme, accent );
      }

    }
  }

 }
.est_inp_wrap{
  padding-top: rem(8px);
  padding-bottom: rem(14px);
  margin-bottom: rem(14px);
  border-bottom: 1px solid;
      border-color: map-get($theme , brdr );
}
 .est_right{
  padding-top: rem(40px);
  padding-left: rem(15px);
  @media only screen and (max-width: 767px){
    padding-top: rem(10px);
    padding-left: rem(10px);
  }
  button{
    border: none;
    border-radius: 4px;
    padding: rem(5px) rem(15px) rem(5px) rem(15px);
    font-size: rem(14px);
    text-align: center;
    margin-left: 1%;
    background-color: map-get($theme,colorL );
    color: map-get($theme, scWrite );
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
    margin-top: rem(4px);
  }

 }
 
 .communications{
   width: 100%;
   height: auto;
   padding-bottom: rem(20px);
   float: left;




  .communicat_tab{
    width: 100%;
    padding: rem(12px);
    background-color: map-get($theme, color );
    color: map-get($theme, scWrite );
    border-radius: 6px 6px 6px 6px;
 
    i{

      font-size: 1.6em;

    }
    
    span{
      font-size: 1em;
      position:absolute;
      padding: rem(6px);
    }
  }

 
  .collapse_ind{
    background-color: map-get($theme, scWrite );
    padding-bottom: rem(50px);
    border-radius: 0px 0px 6px 6px;
    
  }
  .message_wrap{
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    width: 95%;
    margin-left: 2.5%;
    height: auto;
    border-bottom: 2px solid;
    border-color: map-get($theme ,color );
  
    .dowd_link{
      border: 1px solid;
    border-color: map-get($theme , brdr );
    padding: rem(5px);
    padding-left: rem(15px);
    padding-right: rem(15px);
    border-radius: 15px;
    margin-top: rem(15px);
    color: map-get($theme , header-link );
    }
    h5{
      font-weight: 700;
      padding-bottom: rem(10px);
     

    }
    .msg_footer{
      width: 100%;
      

      h6{
       width: 50%;
       float: left;
       color: map-get($theme, color );
       @media only screen and (max-width: 767px){
        font-size: rem(10px);
      }
      }
      h6:nth-child(2n) {
        text-align: right;
      }
    }
  }
 }
 .message_rpl_wrap{
  padding-top: rem(30px);
  padding-bottom: rem(30px);
  width: 95%;
  margin-left: 2.5%;
  height: auto;
   
  textarea{
    min-height: 180px;
    border: 1px solid;
    border-color: map-get($theme , brdr );
}
.msg_footer{
  background-color: map-get($theme, Bgmat );
  padding: rem(10px);
  float: left;
  width: 100%;
  height: auto;

  button{

    margin-left: rem(5px);
    
  }

  

}
 }
 .btn_sav{
  border: none;
  border-radius: 4px;
  padding: rem(5px) rem(15px) rem(5px) rem(15px);
  font-size: rem(14px);
  text-align: center;
  margin-left: 2%;
  margin-right: 1%;
  background-color: map-get($theme,accent );
  color: map-get($theme, scWrite );
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  margin-top: rem(4px);

  @media only screen and (max-width: 767px){
    max-width: 150px;
  }
  
 }
 .btn_cnl{
  border: none;
  border-radius: 4px;
  padding: rem(5px) rem(15px) rem(5px) rem(15px);
  font-size: rem(14px);
  text-align: center;
  margin-left: 2%;
  margin-right: 1%;
  background-color: map-get($theme,brdr );
  color: map-get($theme, scWrite );
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  margin-top: rem(4px);
  @media only screen and (max-width: 767px){
    max-width: 150px;
  }
 }
 .btn_chs{
  border: none;
  border-radius: 4px;
  padding: rem(5px) rem(15px) rem(5px) rem(15px);
  font-size: rem(14px);
  text-align: center;
  margin-left: 2%;
  margin-right: 1%;
  background-color: map-get($theme,colorL );
  color: map-get($theme, scWrite );
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  margin-top: rem(4px);
  @media only screen and (max-width: 767px){
    max-width: 150px;
  }
 }
 .flt_rgt{
   float: right;
 }

 .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
.btn{
  background-color: transparent;
  padding:  rem(4px);
  transform: rotate(20deg);
}

}


.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.ser_view{
  padding-left: rem(25px);
  label{
    width: 100%;
    padding-top: rem(20px);
    color: map-get($theme,brdrlb );
  }
  h6{
    padding-bottom: rem(20px);
    font-weight: 600;
  }
}
.clr_xn{
  color: map-get($theme,accent );
  font-size: rem(14px); 
}
.ser_view2{
  padding-left: rem(15px);
  label{
    width: 100%;
    padding-top: rem(8px);
    color: map-get($theme,brdrlb );
  }
  h6{
    padding-bottom: rem(8px);
    font-weight: 600;
  }
}
.attch_div{
  width: auto;
  height: auto;
  float: left;
  padding: rem(3px);
  color: map-get($theme,accent );
  font-size: rem(12px);
  text-align: center;
}


.statu_sel{
  width: 100%;
  height: auto;
  padding-top: rem(22px);
  padding-bottom: rem(22px);

  .mtl-label{
    border: 1px solid;
    border-color: map-get($theme , brdr );
  }

}
.log_remb{
  padding-top: rem(12px);
  padding-bottom: rem(22px);
}
.min_in{

  h3{
      color: map-get($theme , accent );
       @include font($fontB);
       padding-bottom: rem(22px);
       @media only screen and (max-width: 767px){
         font-size: rem(14px);}
  }
}
.top_wrap_hd{
  width: 100%;
  height: auto;
  background-color: map-get($theme, hdtBg);
  padding: rem(12px);
  padding-left: 2.5%;
  color: map-get($theme, colorL );
  position: relative;

  .btn_add_emp{
    padding: rem(10px);
    font-size:rem(28px);
    background-color:map-get($theme, btn-grn ); 
    border-radius: 50%;
    line-height: .56em;
    color:map-get($theme, scWrite ); 
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 1;
  }

}
.raw_in{
  width: 100%;
  height: auto;
  display: block;
  float: left;
  padding-top: rem(12px);

}

.form_chbx{
  width: 100%;
  height: auto;
  float: left;
  padding-top: rem(34px);
}

.form_wrap  {

  table{

     border-collapse: collapse;
      border-radius: 5px;
      border-style: hidden;
      box-shadow: 0 0 0 2px #efecdd; 
   

    thead{
      background-color: map-get($theme, Bgmat );
     
      
    }
    tr{
      border-top: 2px solid #efecdd;

      .btn_edit{
        font-size:rem(18px);
        color: map-get($theme, text );
        margin-right: 1%;
 
      }
    }

  }
 

}
.rat-wrap{
  padding-top: rem(30px);
h6{
  padding-bottom: rem(20px);
}
.rating{
  width: 100%;
}
}
.top-find{
  width: 100%;
  padding:rem(20px); 
  background-color: map-get($theme, hdtBg);
  margin-bottom: rem(20px); 
}
.emp-popup-wrap{
  padding-left: rem(20px); 
  padding-right:  rem(20px); 
  .emp-popup{
    padding:rem(7px); 
  
  }
}
.filter_hd{

  padding-bottom:  rem(10px); 
  color: map-get($theme, brdrlb);
}