header.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  position: absolute;
  height: $navbar-height;
  color: map-get($theme, header-text);
  @media only screen and (max-width: 997px){
    height: 75px;
  }
  &.home {
    &, .navbar-default {
      background-color: transparent;

      @media only screen and (max-width: 997px){
        background: map-get($theme,scWrite );
      }
     
    }
  }
  .container {
    position: relative;
  }
  a {
    color: map-get($theme, header-link);
    i,
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .language{
    position: absolute;
    width: auto;
    right: 200px;
    padding-top: rem(33px);
    height: auto;
    @media only screen and (max-width: 1267px){
      padding-top: rem(13px);

    }
    @media only screen and (max-width: 997px){
      right: 0px;
    }
    .actve{
      color: map-get($theme, accent );
      cursor: default;
    }
  }
  .contact {
    position: absolute;
    right: $grid-gutter-width / 2;
    padding-top: rem(8px);
    @media only screen and (max-width: 1267px){
      padding-top: rem(3px);
    }
    @media only screen and (max-width: 997px){
      padding-top: rem(20px);
    }

    a {
      text-align: left;
      height: rem(80px);
      display: inline-block;
      padding-top: rem(14px);
      padding-bottom: rem(14px);
      &.phone {
        .i {
          line-height: 1em;
          width: rem(34px);
          height: rem(34px);
          padding: rem(7px);
          border-radius: 50%;
          font-size: em(20px);
          color: map-get($theme, header-bg);
          background: map-get($theme, header-text);
        }
        .text {
          font-size: rem(15px);
        }
        .number {
          font-size: rem(16px);
          @include font($fontBK);
          color: map-get($theme, accent);
        }
      }
    }
  }
  .navbar-nav {
    &.navbar-right {
      margin-top: 82px;
      @media only screen and (max-width: 1267px){
        margin-top:rem(45px);
      }

      @media only screen and (max-width: 767px){
        margin-top:rem(8px);
      }
    }
    & > li {
      & > a {
        @include font($fontB);
        padding-top: rem(11px);
        padding-bottom: rem(11px);
        text-transform: uppercase;
        &:hover {
          color: map-get($theme , accent );
          background-color: transparent;
        }

      }
      &.user {
        & > a {
          text-transform: none;
          @include font($font);
          display: inline-block;

          &:hover {
            color: map-get($theme , accent );
            background-color: transparent;
          }
          }

        }
      
    }
  }
}



header.head_inner{
  background-image:url("#{$img}/head_img.png");
  height: 195px;
  

  @media only screen and (max-width: 1067px){
    background-image: none;
    height: 100px;
  }
}

.navbar-brand{
  padding-top:rem(40px);

  @media only screen and (max-width: 1067px){
    padding-top:rem(8px);
    height: 70px;
  }
  @media only screen and (max-width: 576px){
    width: 70%;
  }

  img{
    @media only screen and (max-width: 767px){
      width: 50%;
    }
  }
}

.navbar-toggle{
  @media only screen and (max-width: 1067px){
    margin-top:rem(5px);
    margin-bottom: rem(5px);
    border: none;
    color: map-get($theme, accent);
    font-size: rem(30px);
  }
  @media only screen and (max-width: 576px){
    margin-top:rem(0px);
    padding-top: 0px;
  }
}
.navbar{
  @media only screen and (max-width: 997px){
    min-height: 60px;
  }
}
