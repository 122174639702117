$baseFontSize: 15px;
$fontFamily: 'Roboto', sans-serif;

// COMMON FONT CHART
// -----------------
$fontUL: 100;   // 'fontName ultralight', sans-serif;
$fontT: 200;    // 'fontName thin', sans-serif;
$fontL: 300;    // 'fontName light', sans-serif;
$font: 400;     // 'fontName regular', sans-serif;
$fontM: 500;    // 'fontName medium', sans-serif;
$fontSB: 600;   // 'fontName semibold', sans-serif;
$fontB: 700;    // 'fontName bold', sans-serif;
$fontH: 800;    // 'fontName heavy', sans-serif;
$fontBK: 900;   // 'fontName black', sans-serif;

$theme: (
    baseFontSize: $baseFontSize,
    fontFamily: $fontFamily,
    font: $font,
    fontL: $fontL,
    fontT: $fontT,
    fontM: $fontM,
    fontB: $fontB,
    fontH: $fontH,
    fontUL: $fontUL,
    fontSB: $fontSB,
    fontBK: $fontBK,
    eror:#c62323,
    text: #222,
    bg: #fff,
    matBg: #fffaf0,
    hdtBg: #d6d4c8,
    Bgmat: #efecdd,
    write: #fff,
    scWrite: #fff,
    color: #34314c,
    colorL: #524f67,
    accent: #d1a217,
    scColor: #6c5a24,
    accent-drk: #574204,
    link: #d1a217,
    brdr: #b7b7b7,
    brdrlb: #838181,
    header-bg: #fff,
    header-text: #222,
    header-link: #222,
    footer-bg: #181201,
    footer-text: #e2dede,
    footer-link: #eee,
    btn-grn: #11c84c,
    types: (
        primary: #579993,		//	map-get($theme, primary);
        success: #079633,		//	map-get($theme, success);
        info: #006ed0,			//	map-get($theme, info);
        warning: #f2e1ad,		//	map-get($theme, warning);
        danger: #c62323,		//	map-get($theme, danger);
        accent: #d1a217,
        light: #fff,
        dark: #000
    )
);

$color: map-get($theme, types);

$bgColor:
	"default"	#fff	map-get($theme, text),
	"primary"	map-get($color, primary)	#fff,
	"success"	map-get($color, success)	#fff,
	"info"	map-get($color, info)	#fff,
	"warning"	map-get($color, warning)	#fff,
	"danger"	map-get($color, danger)	#fff,
	"theme" map-get($theme, color)	#fff,
	"secondary" map-get($theme, scColor)	#fff
;
