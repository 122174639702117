.mDataTable {
    font-size: 13px;

    // position: relative;
    white-space: nowrap;
    border-collapse: collapse;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.12);

    .mtl {
        margin: 0px -10px -18px -10px;
    }

    thead {
        padding-bottom: 3px;

        .selected {
            margin-top: 0;
        }
    }

    tbody {
        tr {
            height: 48px;

            // position: relative;
            -webkit-transition-duration: 0.28s;
            -webkit-transition-property: background-color;
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 0.28s;
            transition-property: background-color;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

            &.selected {
                background-color: #e0e0e0;
            }

            &:hover {
                background-color: #eeeeee;
            }
        }
    }

    td, th {
        //text-align: #{$left};
        vertical-align: bottom;
        padding: 8px;

        &:first-of-type {
            padding-#{$left}: 12px;

            & > label:not(.btn):not(.label) {
                padding-#{$left}: 12px;
                margin-#{$left}: -12px;
            }
        }

        &:last-of-type {
            padding-#{$right}: 12px;

            label:not(.btn):not(.label) {
                padding-#{$right}: 12px;
                margin-#{$right}: -12px;
            }
        }

        &.t {
            &-l {
                text-align: #{$left};
            }

            &-r {
                text-align: #{$right};
            }

            &-c {
                text-align: center;
            }

            &-j {
                text-align: justify;
            }
        }

        &.nonNumeric {
            text-align: #{$left};
        }

        &.numeric {
            text-align: #{$right};
        }

        a {
            &:not(.btn) {
                color: inherit;
            }

            text-decoration: none;
        }

        & > label:not(.btn):not(.label),
        & > a:not(.btn),
        & > a:not(.link) {
            display: block;
            padding: 8px;
            margin: -8px;
        }

        .link {
            color: map-get($theme, link);

            &:hover, &:focus, &:active {
                color: map-get($theme, link);
            }
        }
    }

    td {
        height: 48px;
        padding-top: 12px;

        // position: relative;
        vertical-align: middle;
        box-sizing: border-box;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &.selected {
            vertical-align: middle;
        }

        & > label:not(.btn):not(.label),
        & > a:not(.btn) {
            padding-top: 12px;
            margin-top: -12px;
        }
    }

    th {
        height: 48px;
        color: #666;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        padding-bottom: 8px;
        margin-bottom: -8px;
        @include font($fw-m);
        box-sizing: border-box;
        vertical-align: bottom;
        text-overflow: ellipsis;

        //font-weight: bold;
        // position: relative;
        &.sortedAsc,
        &.sortedDesc {
            color: rgba(0, 0, 0, 0.87);

            &:before {
                ///* ICON SORTING */
                line-height: 1;
                font-size: 16px;
                word-wrap: normal;
                font-style: normal;
                font-weight: normal;
                vertical-align: sub;
                text-transform: none;
                display: inline-block;
                margin-#{$right}: 5px;
                letter-spacing: normal;
                content: $i-arrow_downward;
                -moz-font-feature-settings: 'liga';
                font-feature-settings: 'liga';
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
                font-family: "#{$icomoon-font-family}";
            }

            &:hover {
                cursor: pointer;

                &:before {
                    color: rgba(0, 0, 0, 0.26);
                }
            }
        }

        &.sortedDesc:before {
            content: $i-arrow_upward;
        }
    }

    &.fw {
        width: 100%;
    }

    &-center {
        margin: 0 auto;
    }

    &-nonNumeric {
        text-align: #{$left};
    }

    &-numeric {
        text-align: #{$right};
    }

    .label {
        font-size: 85%;
        font-weight: normal;
        font-weight: 600;
        @include font($fw-m);
    }
}
