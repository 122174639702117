$grid-columns:      24;
$grid-gutter-width: 20px;
// $grid-gutter-width: 20px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$screen-xl: 1390 + ($grid-gutter-width * 2);
// $screen-xl: 1380 + ($grid-gutter-width * 2);
// $container-xl-desktop: (1380 + $grid-gutter-width);
$container-xl-desktop: (1390 + $grid-gutter-width);

$border-radius-base: 4px;

$navbar-height: 124px; // + border-thickness(2px)
$navbar-default-border: transparent;
$navbar-default-bg: map-get($theme, header-bg);
$navbar-default-color: map-get($theme, header-text);

$navbar-default-link-color: map-get($theme, header-link);

$navbar-default-link-hover-color: map-get($theme, header-link);
$navbar-default-link-hover-bg: rgba(map-get($theme, header-link), 0.16);

$navbar-default-link-active-color: map-get($theme, header-link);
$navbar-default-link-active-bg: map-get($theme, write);

$navbar-default-link-disabled-color: rgba(map-get($theme, header-text), 0.87);
$navbar-default-link-disabled-bg: rgba(map-get($theme, header-text), 0.87);

$brand-primary: map-get($color, primary);
$brand-success: map-get($color, success);
$brand-info:    map-get($color, info);
$brand-warning: map-get($color, warning);
$brand-danger:  map-get($color, danger);
$brand-secondary:  map-get($color, secondary);

$grid-float-breakpoint: $screen-md-min;