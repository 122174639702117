footer.footer {
    color: map-get($theme, footer-text);
    background: map-get($theme, footer-bg);
    padding: rem(30px);
    @media only screen and (max-width: 1067px){
         padding: rem(2px);
    }


    .copyright{ border-top: 1px solid;
        border-color: map-get($theme, brdr );
        padding-top: rem(20px);
        color: map-get($theme, brdr );
        font-size: rem(12px);
        span{
            float: right;

            img{
                padding-left: rem(20px);
            }

            @media only screen and (max-width: 1067px){
                padding-top: rem(20px);
            }
        }

    }
    .foot_l{
        width: 100%;
        float: left;
        padding-bottom: rem(20px);

        @media only screen and (max-width: 1067px){
            text-align: center;
        }
    }
    .foot_m{
        width: 100%;
        float: left;
        padding-bottom: rem(20px);
        text-align: center;


        a{
            width:auto;
            height: auto;
            float: none;
            font-size: rem(26px);
        }
        img{
            margin:  rem(7px);
        }
    }
.foot_r{
    width: 100%;
        float: left;
        padding-bottom: rem(20px);
        
        span{
            float: left;
            width: 100%;
            padding-top: rem(10px);
            text-align: right;
            @media only screen and (max-width: 1067px){
                text-align: center;
            }
        }
}
    a{
        float: left;
        width: 100%;
        padding-top: rem(10px);
        color: map-get($theme, footer-text);
    }
}
