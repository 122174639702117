// /**
//  * Grid setup
//  *
//  * The grid will calculate dimensions based on these two variables:
//  * $fgColumns will inform the grid loops how many columns there should be.
//  * $fgGutter will inform the grid loops how big eac column's gutters should be.
//  *
//  * The grid will name columns, rows, offsets based on these three variables:
//  * $fgRow string used for the row class
//  * $fgColumn string used for the column class
//  * $fgOffset string used for the offset class
//  */

$fgColumns: 24 !default;
$fgGutter: 10px !default;

$fgRow: "f-row" !default;
$fgColumn: "f-col" !default;
$fgOffset: "f-offset" !default;

// /**
//  * Break point namespace object
//  *
//  * Set the default namespace object with these defaults with the
//  * understanding that you can pass in whatever you might require for your site.
//  *
//  * $fgBreakpoints is a Sass list with nested lists inside. Each sub list defines two things.
//  * 1. The namespace for that breakpoint. (Required) (i.e. xs, sm, md, lg)
//  * 2. The min-width measurement for the breakpoint for that namespace. (i.e. 48em, 62em, 75em)
//  *
//  * Note: These should be in the proper order (at least till libsass handles map keys properly).
//  *
//  * Note: If the measurement is left out then it will be skipped when generating
//  * the grid and applied to global styles.
//  *
//  */

$fgBreakpoints: xs, (sm, 768px), (md, 992px), (lg, 1200px) !default;

// /**
//  * Flex LIQUID
//  *
//  * Liquid Styled Flex
//  */

.flex,
%flex {
    @extend %flexbox !optional;

    @include flex-wrap(nowrap);

    &.L,
    &.R{
        float: none;
    }

    &.L {
        @include justify-content(flex-start);
    }

    &.R {
        @include justify-content(flex-end);
    }

    &Justify, &.justify {
        @include justify-content(space-between);
    }

    &ContentMiddle, &.contentMiddle {
        @include justify-content(center);
    }

    &ForceJustify, &.forceJustify {
        @include justify-content(space-around);
    }

    &Top, &.top {
        @include align-self(flex-start);
    }

    &Middle, &.middle {
        @include align-self(center);
    }

    &Bottom, &.bottom {
        @include align-self(flex-end);
    }

    &End, &.end {
        @include align-items(flex-end);
    }

    &Center, &.center {
        @include align-items(center);
    }

    &Start, &.start {
        @include align-items(flex-start);
    }

    &Stretch, &.stretch {
        @include align-items(stretch);
    }

    &Baseline, &.baseline {
        @include align-items(baseline);
    }

    &-wrap {
        @include flex-wrap(wrap);
    }
}

.flex, %flex,
.flexTable {
    .cell {
        padding: 0px rem(10px);

        @include flex-grow(1);

        @for $i from 0 through 6 {
            &-#{$i} {
                @include flex-grow($i);
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .flex:not(.nowrap) {
        @include flex-wrap(wrap);
    }
}

.flexTable {
    display: block;

    .fTR {
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        flex-grow: 0;
        -webkit-flex-grow: 0;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        width: 100%;

        // padding-left: 5px;
        // padding-right: 5px;
        .fTD {
            word-break: break-all;

            &.text {
                flex-grow: 1;
                -webkit-flex-grow: 1;
                padding-right: rem(5px);
            }

            &.ellipsis {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.fTable {
    .fTR {
        @extend %flex;

        & > span {
            @extend .cell !optional;
        }
    }
}

// /**
//  * Calculate column size percentage
//  */
@function getColPercent($colNum) {
    @return $colNum / $fgColumns * 100%;
}

// /**
//  * Spacing mixin to create uniform margin/padding
//  */
@mixin spacing($value, $type: margin, $orientation: vertical) {
    @if $orientation == vertical {
        #{$type}-top: $value;
        #{$type}-bottom: $value;
    }
    @else {
        #{$type}-left: $value;
        #{$type}-right: $value;
    }
}

// /**
//  * Row wrapper class, flex box parent.
//  */
.#{$fgRow} {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @extend %flexbox; // display: flex;
    @include flex-direction(row); // flex-direction: row;
    @include flex-wrap(wrap); // flex-wrap: wrap;
    @include spacing(-$fgGutter, margin, horizontal);
}

%#{$fgColumn} {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @extend %flexbox; // display: flex;
    @include flex-direction(column); // flex-direction: column;
    @include flex-grow(0); // flex-grow: 0;
    @include flex-shrink(0); // flex-shrink: 0;
    @include spacing($fgGutter, padding, horizontal);
}

%#{$fgColumn}-flex {
    @extend %#{$fgColumn};

    @include flex-grow(1); // flex-grow: 1;
    @include flex-basis(0); // flex-basis: 0;
    max-width: 100%;
}

// /**
//  * Generate a set of grid column classes using a namespace
//  *
//  * .#{ $fgColumn }-[namespace] for intelligent column division
//  * .#{ $fgColumn }-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
//  * .#{ $fgOffset }-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
//  */

@mixin grid-base($namespace) {
    .#{$fgColumn}-#{$namespace} {
        @extend %#{$fgColumn}-flex;
    }

    @for $i from 1 through $fgColumns {
        .#{$fgColumn}-#{$namespace}-#{$i} {
            @extend %#{$fgColumn};
        }
    }
}

@mixin grid-sizes($namespace) {
    // Run the loop for as many columns as speficied
    @for $i from 1 through $fgColumns {
        .#{$fgColumn}-#{$namespace}-#{$i} {
            // flex-basis: getColPercent($i);
            @include flex-basis(getColPercent($i));

            max-width: getColPercent($i);
        }
    }

    @for $i from 1 through $fgColumns {
        // Don't include the .off class for the last column
        @if $i != $fgColumns {
            .#{$fgOffset}-#{$namespace}-#{$i} {
                margin-left: getColPercent($i);
            }
        }
    }
}

// /**
//  * Build the grid in two steps, to help minimize file size
//  * Step 1, for each namespace, create the grid-base
//  * Step 2, for each namespace, wrap the col width/offset measurements in their breakpoint media query
//  */
@each $val in $fgBreakpoints {
    $namespace: nth($val, 1);

    @include grid-base($namespace);
}

@each $val in $fgBreakpoints {
    @if length($val) == 1 {
        $namespace: nth($val, 1);

        @include grid-sizes($namespace);
    }
    @else {
        $namespace: nth($val, 1);
        $size: nth($val, 2);

        @media only screen and (min-width: #{$size}) {
            @include grid-sizes($namespace);
        }
    }
}

// [class*=f-col-] {
//   & > * {
//     @include flex(1);
//   }
// }

// RTL Support
[dir="rtl"] {
    // .f-col-xs, .f-col-sm, .f-col-md, .f-col-lg, .f-col-xs-1, .f-col-xs-2, .f-col-xs-3, .f-col-xs-4, .f-col-xs-5, .f-col-xs-6, .f-col-xs-7, .f-col-xs-8, .f-col-xs-9, .f-col-xs-10, .f-col-xs-11, .f-col-xs-12, .f-col-xs-13, .f-col-xs-14, .f-col-xs-15, .f-col-xs-16, .f-col-xs-17, .f-col-xs-18, .f-col-xs-19, .f-col-xs-20, .f-col-xs-21, .f-col-xs-22, .f-col-xs-23, .f-col-xs-24, .f-col-sm-1, .f-col-sm-2, .f-col-sm-3, .f-col-sm-4, .f-col-sm-5, .f-col-sm-6, .f-col-sm-7, .f-col-sm-8, .f-col-sm-9, .f-col-sm-10, .f-col-sm-11, .f-col-sm-12, .f-col-sm-13, .f-col-sm-14, .f-col-sm-15, .f-col-sm-16, .f-col-sm-17, .f-col-sm-18, .f-col-sm-19, .f-col-sm-20, .f-col-sm-21, .f-col-sm-22, .f-col-sm-23, .f-col-sm-24, .f-col-md-1, .f-col-md-2, .f-col-md-3, .f-col-md-4, .f-col-md-5, .f-col-md-6, .f-col-md-7, .f-col-md-8, .f-col-md-9, .f-col-md-10, .f-col-md-11, .f-col-md-12, .f-col-md-13, .f-col-md-14, .f-col-md-15, .f-col-md-16, .f-col-md-17, .f-col-md-18, .f-col-md-19, .f-col-md-20, .f-col-md-21, .f-col-md-22, .f-col-md-23, .f-col-md-24, .f-col-lg-1, .f-col-lg-2, .f-col-lg-3, .f-col-lg-4, .f-col-lg-5, .f-col-lg-6, .f-col-lg-7, .f-col-lg-8, .f-col-lg-9, .f-col-lg-10, .f-col-lg-11, .f-col-lg-12, .f-col-lg-13, .f-col-lg-14, .f-col-lg-15, .f-col-lg-16, .f-col-lg-17, .f-col-lg-18, .f-col-lg-19, .f-col-lg-20, .f-col-lg-21, .f-col-lg-22, .f-col-lg-23, .f-col-lg-24{
    //   -webkit-flex-direction: column-reverse;
    //   -ms-flex-direction: column-reverse;
    //   flex-direction: column-reverse;
    // }
}
