.bg_service {
  .card {
    margin: 0.6rem;
    margin-bottom: 2rem;
    padding: rem(14px);
    text-align: center;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    min-height: 320px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 1.2rem;
    }
    @media only screen and (max-width: 1091px) and (min-width: 768px) {
      margin: 0.1rem;
      margin-bottom: 1.2rem;
      min-height: 300px;
    }

    img {
      padding: rem(22px);
      max-height: 100px;
      min-height: 75px;
      @media only screen and (max-width: 1091px) and (min-width: 768px) {
        padding: rem(5px);
        max-height: 70px;
        min-height: 55px;
      }
    }

    .crd_ul {
      padding-left: rem(2px);
      padding: rem(2px);
      & > li {
        text-align: left;
        list-style: none;
        border: 0px;
        border-bottom: 1px;
        border-style: dotted;
        border-color: map-get($theme, colorL);
        padding-top: rem(12px);
        padding-bottom: rem(12px);
        color: map-get($theme, text);

        @media only screen and (max-width: 1491px) and (min-width: 868px) {
          font-size: rem(12px);
        }

        i {
          background: map-get($theme, colorL);
          color: map-get($theme, scWrite);
          border-radius: 50%;
          padding: rem(1px);
          margin-right: 0.1rem;
        }
      }
    }
    a {
      padding-top: rem(10px);
      text-align: right;
      width: auto;
      float: right;
      color: map-get($theme, accent);
      span {
        text-align: right;
        float: left;
      }
      i {
        font-size: 1.3em;
      }
    }
  }
}

.hd_wrap {
  padding: rem(100px);
  text-align: center;
  @media only screen and (max-width: 767px) {
    padding: rem(30px);
  }

  h1 {
    font-size: rem(48px);
    @include font($fontB);
    @media only screen and (max-width: 1199px) {
      font-size: rem(42px);
    }
    @media only screen and (max-width: 768px) {
      font-size: rem(36px);
    }
   
  }

  @media only screen and (max-width: 1091px) and (min-width: 768px) {
    padding: rem(20px);
  }
  @media only screen and (max-width: 576px) {
    padding: rem(10px);
  }
 
  h5 {
    color: map-get($theme, scColor);
    max-width: 40%;
    margin-left: 30%;
    margin-top: rem(10px);
    font-size: rem(18px);
    @media only screen and (max-width: 1199px) {
      font-size: rem(15px);
    }
    @media only screen and (max-width: 768px) {
      font-size: rem(14px);
      max-width: 60%;
    margin-left: 20%;
    }

    @media only screen and (max-width: 576px) {
      font-size: rem(12px);
      max-width: 90%;
    margin-left: 5%;
    }
  }
}
.bg_how_it {
  background-image: linear-gradient(#eeeeee, #fffcf7, #dcba7a, #d9b36a);
  margin-top: rem(80px);
  margin-bottom: rem(10px);
  padding-bottom: rem(80px);
}
.bg_centers {
  background-image: linear-gradient(#fbf8ef, #f2efe6, #eae0bc);
  margin-top: rem(80px);
  padding-bottom: rem(100px);
  margin-top: rem(20px);
  
  .hd_wrap {
    padding-bottom: rem(30px);

    @media only screen and (max-width: 576px) {
      padding-bottom: rem(70px);
    }
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: rem(10px);
  }

  

}
.inp_sear {
  min-width: 42%;
  padding: rem(8px);
  background-color: transparent;
  border: 1px;
  border-style: solid;
  border-color: map-get($theme, brdr);
  margin-top: rem(20px);
  margin-right: rem(10px);
  @media only screen and (max-width: 767px) {
    min-width: 30%;
    float: left;
    margin-right: rem(1px);
    padding: rem(5px);
  }
}
.bt_serch {
  background-color: map-get($theme, accent);
  padding: rem(8px);
  color: map-get($theme, scWrite);
  min-width: 10%;
  box-shadow: 5px 5px 7px 0px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 767px) {
    float: right;
    min-width: 5%;
    padding: rem(5px);
    margin-top: rem(20px);
  }
}
.map_bg {
  width: 100%;
  height: auto;
  background-color: #0000007d;
  top: 0px;

  @media only screen and (max-width: 767px) {
    margin-top: rem(20px);
  }
}
.Addr_cdn {
  width: 80%;
  margin-left: 10%;
  padding: rem(30px);
  padding-left: em(0px);
  color: map-get($theme, scWrite);
  border-bottom: 1px solid #cccccc7d;
}
#map_container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: rem(30px);
}
#map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 1;
}
#howItWorks {
  .cls-1,
  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-16,
  .cls-18,
  .cls-19,
  .cls-6,
  .cls-8 {
    fill: #fff;
  }

  .cls-1,
  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-18,
  .cls-6,
  .cls-8 {
    stroke: #dec697;
    stroke-dasharray: 4 2;
  }

  .cls-1,
  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-6,
  .cls-8 {
    stroke-width: 1px;
  }

  .cls-1 {
    filter: url(#filter);
  }

  .cls-2 {
    fill: #c1930d;
  }

  .cls-15,
  .cls-16,
  .cls-17,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5 {
    fill-rule: evenodd;
  }

  .cls-3,
  .cls-4,
  .cls-5 {
    fill: #f4d69d;
  }

  .cls-3 {
    filter: url(#filter-2);
  }

  .cls-4 {
    filter: url(#filter-3);
  }

  .cls-5 {
    filter: url(#filter-4);
  }

  .cls-6 {
    filter: url(#filter-5);
  }

  .cls-7,
  .cls-9 {
    font-size: 15px;
  }

  .cls-7 {
    text-anchor: middle;
  }

  .cls-19,
  .cls-7,
  .cls-9 {
    font-family: Roboto;
  }

  .cls-8 {
    filter: url(#filter-6);
  }

  .cls-10 {
    filter: url(#filter-7);
  }

  .cls-11 {
    filter: url(#filter-8);
  }

  .cls-12 {
    filter: url(#filter-9);
  }

  .cls-13 {
    filter: url(#filter-10);
  }

  .cls-14 {
    filter: url(#filter-11);
  }

  .cls-15 {
    fill: #a9810b;
  }

  .cls-16,
  .cls-17 {
    stroke: #a28449;
  }

  .cls-17 {
    fill: #ffe1a7;
  }

  .cls-18 {
    filter: url(#filter-12);
  }

  .cls-19 {
    font-size: 24px;
    font-weight: 800;
  }

  .cls-20 {
    fill: #ffd991;
  }

  .cls-21 {
    fill: #feda94;
  }
}

#whychoos {
  margin-left: 10%;

  .cls-1,
  .cls-2,
  .cls-6 {
    fill: none;
    stroke: #dab46b;
    stroke-width: 1px;
  }

  .cls-1,
  .cls-2 {
    stroke-dasharray: 4 2;
  }

  .cls-1,
  .cls-2,
  .cls-3,
  .cls-5,
  .cls-6 {
    fill-rule: evenodd;
  }

  .cls-1 {
    opacity: 0.3;
  }

  .cls-2 {
    opacity: 0.6;
  }

  .cls-3 {
    fill: #34314c;
  }

  .cls-4 {
    opacity: 0.8;
  }

  .cls-5 {
    fill: #ffda75;
  }

  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-7,
  .cls-8,
  .cls-9 {
    fill: #fff;
  }

  .cls-7 {
    filter: url(#filter);
  }

  .cls-8 {
    filter: url(#filter-2);
  }

  .cls-9 {
    filter: url(#filter-3);
  }

  .cls-10 {
    filter: url(#filter-4);
  }

  .cls-11 {
    filter: url(#filter-5);
  }

  .cls-12 {
    filter: url(#filter-6);
  }

  .cls-13 {
    filter: url(#filter-7);
  }

  .cls-14 {
    filter: url(#filter-8);
  }

  .cls-15 {
    font-size: 20px;
    fill: #b99757;
    font-weight: 800;
  }

  .cls-15,
  .cls-16,
  .cls-17 {
    font-family: Roboto;
  }

  .cls-16,
  .cls-17 {
    font-size: 18px;
    fill: #222;
    font-weight: 500;
  }

  .cls-16 {
    text-anchor: end;
  }
}

.btn_fx_callbk {
  position: fixed;
  z-index: 99;
  top: 50%;
  right: -115px;
  padding: rem(10px);
  transform: rotate(90deg);
  border: none;
  border-radius: 0px 0px 7px 7px;
  color: map-get($theme, write);
  background-color: map-get($theme, accent);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
  font-size: rem(20px);
  @include font($fontB);
  word-spacing: rem(3px);

  i{
    font-size: rem(24px);
    transform: rotate(270deg);

  }
}
.h1_inv {
  @include font($fontB);
  color: map-get($theme, accent);
  padding-bottom: 1em;
  padding-top: rem(7px);
}
.top_head_img {
  margin-top: -3em;
}

.top_notif_icon {
  font-size: rem(25px);
}
.top_inn_hd {
  width: 100%;
  min-height: 120px;
  height: auto;
  margin-bottom: rem(12px);

  img {
    width: 10%;
    height: auto;
    float: right;
    @media only screen and (max-width: 767px) {
      width: 20%;
    }
  }
  h6 {
    text-align: right;
    width: 100%;
    float: right;
    @media only screen and (max-width: 767px) {
      font-size: rem(12px);
    }
  }
  h3 {
    color: map-get($theme, accent);
    @include font($fontB);
    @media only screen and (max-width: 767px) {
      font-size: rem(14px);
    }
  }
}
.alert {
  width: 100%;
  padding: rem(12px);
  border: 1px solid;
  float: left;
  border-radius: 5px;
  margin-top: rem(12px);
  margin-bottom: rem(12px);
  @media only screen and (max-width: 767px) {
    padding: rem(8px);
  }
}
.alert-warning {
  border-color: map-get($theme, accent);
  background-color: #d1a21714;
  color: map-get($theme, accent);
  font-weight: 700;
  font-size: rem(18px);
}
.file {
  visibility: hidden;
  position: absolute;
}

.form_wrap {
  padding: rem(22px);
  .form-inp {
    width: 85%;
    padding: rem(8px);
    margin-bottom: rem(15px);
    border: 1px solid;
    border-color: map-get($theme, brdr);
    background-color: map-get($theme, bg);
    margin-right: 0px;

    @media only screen and (max-width: 1467px) {
      width: 81%;
    }
  }
  .form-inp1 {
    width: 100%;
    padding: rem(8px);
    margin-bottom: rem(15px);
    border: 1px solid;
    border-color: map-get($theme, brdr);
    background-color: map-get($theme, bg);
  }
  .txt_ar_2 {
    width: 100%;
    padding: rem(12px);
    margin-top: rem(18px);
    min-height: 120px;
  }
  label {
    width: 100%;
    text-align: left;
    font-weight: 400;
    cursor: default;
    i {
      color: map-get($theme, eror);
    }
  }
  .marg_top {
    float: right;
    cursor: pointer;
  }
  .browse_inp_btn1 {
    min-width: 14%;
    background-color: map-get($theme, accent);
    padding: rem(9px);

    border: none;
    color: map-get($theme, bg);
  }
  .browse_inp_btn2 {
    min-width: 14%;
    background-color: map-get($theme, accent);
    padding: rem(9px);
    padding-left: rem(16px);
    padding-right: rem(16px);
    border: none;
    color: map-get($theme, bg);
    cursor: pointer;

    @media only screen and (max-width: 1267px) {
      padding-left: rem(5px);
      padding-right: rem(5px);
    }
  }
  .mtl-label {
    padding: rem(9px);
    border-width: 1px;
    border-style: solid;
    border-color: map-get($theme, brdr);
  }
  select {
    height: 40px;
  }
  .note_aria {
    width: 100%;
    height: auto;
    margin-top: rem(20px);
    margin-bottom: rem(20px);

    textarea {
      min-height: 120px;
    }
  }
}
.form_in_wrap {
  width: 98.5%;
  margin-left: 0.75%;
  float: left;
  height: auto;
  padding: rem(10px);
  padding-top: rem(25px);
  padding-bottom: rem(25px);
  border: 1px solid;
  border-color: map-get($theme, footer-text);
  margin-top: rem(20px);
  margin-bottom: rem(25px);

  .hd_span {
    float: left;
    width: 20%;
    padding-left: rem(9px);
    margin-right: 70%;
    margin-top: rem(-35px);
    background-color: map-get($theme, bg);
    @include font($fontB);

    @media only screen and (max-width: 767px) {
      width: 75%;
      margin-right: 25%;
    }
  }
  .form-control {
    padding: rem(18px);
  }
}
.card {
  &.light {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
  }
}
.mn_lbt {
  margin-top: rem(20px);
}

.dropzone {
  min-height: 120px;
  border: 1px dashed;
  border-color: map-get($theme, brdr);
  background: white;
  margin-top: rem(20px);
  &.dz-clickable {
    cursor: pointer;
    * {
      cursor: default;
    }
    .dz-message {
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
  &.dz-started .dz-message {
    display: none;
  }
  &.dz-drag-hover {
    border-style: solid;
    .dz-message {
      opacity: 0.5;
    }
  }
  .dz-message {
    text-align: center;
    margin: 2em 0;
  }
  .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
    &:hover {
      z-index: 1000;
      .dz-details {
        opacity: 1;
      }
    }
    &.dz-file-preview {
      .dz-image {
        border-radius: 20px;
        background: #999;
        background: linear-gradient(to bottom, #eee, #ddd);
      }
      .dz-details {
        opacity: 1;
      }
    }
    &.dz-image-preview {
      background: white;
      .dz-details {
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -ms-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
      }
    }
    .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &:hover .dz-details {
      opacity: 1;
    }
    .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 2em 1em;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 150%;
      .dz-size {
        margin-bottom: 1em;
        font-size: 16px;
      }
      .dz-filename {
        white-space: nowrap;
        &:hover span {
          border: 1px solid rgba(200, 200, 200, 0.8);
          background-color: rgba(255, 255, 255, 0.8);
        }
        &:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            border: 1px solid transparent;
          }
        }
        span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 0.4em;
          border-radius: 3px;
        }
      }
      .dz-size span {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 0 0.4em;
        border-radius: 3px;
      }
    }
    &:hover .dz-image img {
      -webkit-transform: scale(1.05, 1.05);
      -moz-transform: scale(1.05, 1.05);
      -ms-transform: scale(1.05, 1.05);
      -o-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
      -webkit-filter: blur(8px);
      filter: blur(8px);
    }
    .dz-image {
      border-radius: 20px;
      overflow: hidden;
      width: 120px;
      height: 120px;
      position: relative;
      display: block;
      z-index: 10;
      img {
        display: block;
      }
    }
    &.dz-success .dz-success-mark {
      -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    }
    &.dz-error .dz-error-mark {
      opacity: 1;
      -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    }
    .dz-success-mark,
    .dz-error-mark {
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -27px;
      margin-top: -27px;
    }
    .dz-success-mark svg,
    .dz-error-mark svg {
      display: block;
      width: 54px;
      height: 54px;
    }
    &.dz-processing .dz-progress {
      opacity: 1;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
    }
    &.dz-complete .dz-progress {
      opacity: 0;
      -webkit-transition: opacity 0.4s ease-in;
      -moz-transition: opacity 0.4s ease-in;
      -ms-transition: opacity 0.4s ease-in;
      -o-transition: opacity 0.4s ease-in;
      transition: opacity 0.4s ease-in;
    }
    &:not(.dz-processing) .dz-progress {
      -webkit-animation: pulse 6s ease infinite;
      -moz-animation: pulse 6s ease infinite;
      -ms-animation: pulse 6s ease infinite;
      -o-animation: pulse 6s ease infinite;
      animation: pulse 6s ease infinite;
    }
    .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);
      -webkit-transform: scale(1);
      border-radius: 8px;
      overflow: hidden;
      .dz-upload {
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        -webkit-transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -ms-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
        transition: width 300ms ease-in-out;
      }
    }
    &.dz-error {
      .dz-error-message {
        display: block;
      }
      &:hover .dz-error-message {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .dz-error-message {
      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      display: none;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      -moz-transition: opacity 0.3s ease;
      -ms-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      border-radius: 8px;
      font-size: 13px;
      top: 130px;
      left: -10px;
      width: 140px;
      background: #be2626;
      background: linear-gradient(to bottom, #be2626, #a92222);
      padding: 0.5em 1.2em;
      color: white;
      &:after {
        content: "";
        position: absolute;
        top: -6px;
        left: 64px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #be2626;
      }
    }
  }
}

.pack_main {
  width: 98.5%;
  margin-left: 0.75%;
  float: left;
  height: auto;
  margin-top: rem(20px);
  background-color: map-get($theme, Bgmat);
  padding: rem(30px);
}

.rate_crd {
  text-align: center;
  color: map-get($theme, text);
  padding: rem(40px);
  width: 95%;
  border-radius: 6px 6px 0px 0px;

  @media only screen and (max-width: 1267px) {
    width: 100%;
    margin-top: rem(10px);
    padding: rem(10px);
  }
  i {
    font-size: rem(100px);
    margin-bottom: rem(20px);
    margin-top: rem(10px);
  }
  span {
    padding: rem(7px);
    margin-top: rem(10px);
    min-width: 50%;
    float: left;
    margin-left: 25%;
    border: 1px solid;
    border-color: map-get($theme, brdr);
    border-radius: 20px;
    font-weight: bold;
  }
}
.rate_foot {
  width: 95%;
  padding: rem(15px);
  background-color: map-get($theme, brdr);
  margin-top: rem(-24px);
  border-radius: 0px 0px 6px 6px;
  color: map-get($theme, scWrite);
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 1267px) {
    width: 100%;
  }

  i {
    display: none;
  }
}

.active_pkg {
  .rate_crd {
    width: 100%;
    padding: rem(50px);
    color: map-get($theme, accent);
    border: 2px solid;
    border-color: map-get($theme, accent);
    margin-top: rem(-10px);

    @media only screen and (max-width: 1267px) {
      width: 100%;
      margin-top: rem(10px);
      padding: rem(10px);
    }

    span {
      border-color: map-get($theme, accent);
    }
  }
  .rate_foot {
    width: 100%;
    background-color: map-get($theme, accent);
    text-align: center;

    i {
      width: 30px;
      height: 30px;
      text-align: center;
      display: block;
      padding-top: rem(5px);
      border-radius: 50%;
      border: 1px solid;
      border-color: map-get($theme, scWrite);
      margin-left: 45%;
    }
    span {
      display: none;
    }
  }
}
.pakg_left {
  padding-top: rem(70px);

  img {
    width: 15%;
  }
  h3 {
    padding-top: rem(10px);
    padding-bottom: rem(10px);
  }
  p {
    padding-right: 10%;
  }
}
.seprate {
  // background: -moz-linear-gradient(90deg, rgb(245, 245, 245) 25%, rgb(153, 153, 153) 50%, rgb(245, 245, 245) 75%);
  // background: -webkit-linear-gradient(90deg, rgb(245, 245, 245) 25%, rgb(153, 153, 153) 50%, rgb(245, 245, 245) 75%);
  // background: -ms-linear-gradient(90deg, rgb(245, 245, 245) 25%, rgb(153, 153, 153) 50%, rgb(245, 245, 245) 75%);
  @include linear-gradient( rgb(245, 245, 245) 25%, rgb(153, 153, 153) 50%, rgb(245, 245, 245) 75%);
  width: 1px;
  height: 50px;
  float: left;
}
