label {
    @include font($font);
    &[for] {
        cursor: pointer;
    }
}

.btn {
    line-height: 1em;
    padding: rem(10px) rem(15px);
    // &:focus {
    //     @include box-shadow(0 0 0 .2rem rgba(map-get($theme, color), .5));
    // }
    .i, span {
        display: inline-block;
        vertical-align: middle;
    }
    
    .i {
        font-size: 1.5em;
    }

    span {
        padding-left: rem(8px);
    }

    &._3d {
        @include box-shadow(0 rem(2px) 0 darken(map-get($theme, color), 24%));
    }

    &-sm {
        font-size: em(14px);
        padding: rem(5px) rem(10px) rem(6px);
    }

    &-xs {
        font-size: em(14px);
        @include font($font);
        padding: rem(4px) rem(10px) rem(5px);
        .i {
            font-size: #{17px / 14px}em;
        }
    }

    &-mtl {
        // @include box-shadow(none);
        background: map-get($theme, write);
        color: map-get($theme, text);
        border: rem(1px) solid map-get($theme, write);
        border-radius: rem(15px);
        &.text-normal {
            text-transform: initial;
        }
        &:hover, &:focus, &:active {
            color: map-get($theme, write);
            background: darken(map-get($color, accent), 6.5%);
        }
    }
    &-accent {
        color: map-get($theme, write);
        background: map-get($color, accent);
        &:hover, &:focus, &:active {
            color: map-get($theme, write);
            background: darken(map-get($color, accent), 6.5%);
        }
    }

    &-icon {
        & > i {
            font-size: rem(20px);
            vertical-align: middle;
        }
    }
}

.input {
    &-group {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        &-addon {
            padding: rem(6px) rem(12px);
            & > select {
                border-radius: 0;
                height: rem(42px);
                border: 0 none transparent;
                padding: rem(6px) rem(12px);
                margin: rem(-8px) rem(-12px); 
            }
            // &.btn {
            //     border-radius: 0;
            // }
        }
    }
}

input[type="checkbox"]{
    &.custom.bold {
        & + label {
            &:before {
                content: '';
                border: 1px solid ;
                border-color: map-get($theme , brdr );
            }
        }
        &:checked {
            & + label {
                &:before {
                    content: $i-tick;
                    color: map-get($theme, accent);
                    font-size: 1em;
                    text-align: center;
                }
            }
        }
    }
}


.selectBox {
    position: relative;
    &:after,
    &:before {
        top: 0;
        right: 0;
        content: '';
        width: rem(32px);
        position: absolute;
        text-align: center;
        pointer-events: none;
    }
    &:before {
        top: 50%;
        z-index: 1;
        height: 1em;
        line-height: 1em;
        margin-top: -0.5em;
        content: $i-angle-down;
        font-family: $icomoon-font-family;
    }
    &:after {
        bottom: 0;
        background: #fff;
    }
    & > select {
        width: 100%;
        height: 100%;
        border: 0 none;
        padding: rem(5px) rem(32px) rem(5px) rem(5px);
    }
}

.datepicker {
    position: relative;
    display: inline-block;
    &:before {
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 1.5em;
        position: absolute;
        background: #fff;
        text-align: center;
        padding: rem(3px) 0;
        content: $i-date_range;
        color: map-get($theme, color);
        font-family: $icomoon-font-family;
    }
    &-sm {
        width: 8em;
    }
    & > input {
        width: 100%;
        padding-left: 1.8em;
        border: 0 none transparent;
        &:hover, &:focus, &:active {
            @include box-shadow(none);
        }
    }
}

.form {
    &-group {
        margin-bottom: rem(15px);
        label {
            font-size: em(14px);
        }
    }
    &-control {
        border-radius: 0;
        height: rem(30px);
        font-size: em(14px);
        border: rgba(#000, 0.5) rem(1px) solid;
        &-static {
            @include font($fontB);
        }
    }
    &-horizontal {
        .control-label {
            padding-top: rem(4px);
        }
    }
}

.rating {
    span.star {
        color: rgba(#929292, 0.5);
        &.active,
        &.active ~ span.star {
            color: #f7ae00;
        }
    }
}

.mtl{
    padding: 0px;
    font-size: em(16px);
    &.mtl-no-message{
        &.mtl-select{
            &:before{
            background: none;
            font-size: em(24px);
            }
        }
    }
}