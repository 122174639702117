.material-primary {
	$labelFocus: #fff;
	$invertColor: $prColor;
	$placeholderColor: rgba(#fff, 0.87);
	.mtl {
		$root: ".mtl";
		select,
		.txtView,
		textarea,
		label[data-control],
		input:not([type="checkbox"]):not([type="radio"]) {
			border-bottom-color: rgba(#fff, 0.87);
			background: transparent;
			@mixin placeholder {
				color: #fff;
			}
			&[disabled] {
				border-bottom-color: rgba(#fff, 0.87); //#AFB1B3;
			}
		}
		&-text { }
		&-select {
			&:before {
				background: $invertColor;
			}
			&:not(.hasValue):not(.mtl-no-label) .mtl-label {
				background: transparent;
			}
		}
		&-label {
			&:before {
				background: $labelFocus;
			}
			&:after {
				background: rgba(#fff, 0.87);
			}
		}

		&.disabled,
		&.hasValue,
		&.onError,
		&.onSuccess,
		&.focus {
			#{$root}-label{
				color: $labelFocus;
			}
		}
		&.onError {
			#{$root}-label {
				&:before { background: $errorColor; }
			}
		}
		&.onSuccess {
			#{$root}-label {
				&:before { background: $successColor; }
			}
		}
		&.disabled {
			#{$root}-label {
				&:before {
					background-image: -webkit-linear-gradient(#{$left},rgba(#fff,.26),rgba(#fff,.26) 33%,transparent 0) !important;
					background-image: linear-gradient(90deg,rgba(#fff,.26) 0,rgba(#fff,.26) 33%,transparent 0) !important;
				}
			}
		}
		&-floatingLabel {
			#{$root}-label {
				color: $placeholderColor;
			}
			&.onError, &.onSuccess, &.focus {
				#{$root}-label {
					color: $labelFocus;
				}
			}
		}
	}
}