.banner{
    @media only screen and (max-width: 767px){
      padding-top: rem(50px);
    }
  }
  .login_wrap{
    position: absolute;
    top: 150px;
    min-height: 300px;
    min-width: 340px;
    opacity: .8;
    height: auto;
    width: 27%;
    right: 0;
    background-color:map-get($theme, bg );
    text-align: center;
    padding: rem(30px);
    border-radius: 0px  0px 6px 6px;
    


   
    @media only screen and (max-width: 1100px){
      width: 50%;
      left: 25%;
      position: relative;
      padding-top:  rem(20px);
      top: 100px;
      padding-bottom: rem(80px);
      border-radius: 6px  6px 6px 6px;
    }

    @media only screen and (max-width: 767px){
      width: 100%;
      left: 0%;
      top: 40px;
      position: relative;
      padding-top:  rem(20px);
      border-radius: 6px  6px 6px 6px;
    }
    h3{
      color: map-get($theme, accent );
      padding-top: rem(15px);
      padding-bottom:  rem(25px);
    }
    input{
      width: 100%;
      padding: rem(5px);
      margin-bottom: rem(15px);
      border: 1px solid;
      border-color: map-get($theme, brdr )

    }
    label{
      width: 100%;
      text-align: left;
      @include font($fontB);
      i{
        color:map-get($theme, eror );
      }
    }
    .btn{
      width: 100%;
      padding: rem(11px);
      margin-top: rem(11px);
      margin-bottom: rem(20px);
      color: map-get($theme, header-bg );
      background-color:  map-get($theme, accent );
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    }
    .link_clickhr{
      @include font($fontB);
      text-decoration: underline;
      margin-top: rem(11px);
      margin-bottom: rem(10px);
      width: 100%;
      float: left;
    }
    .pad_ch{
      padding-top:  rem(12px);
      padding-bottom:  rem(12px);
    }
  }
  .top_user_icon{
    background-color: map-get($theme , accent );
    padding: rem(5px);
    border-radius: 50%;
    color: map-get($theme , bg);
    margin-right: rem(15px);
  }

  .banner_reg{
    background-image:url("#{$img}/login_banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    min-height: 820px;
  
    .form{
      position: relative;
    }
  
    @media only screen and (max-width: 1067px){
      background-position: bottom;
      min-height: 730px;
      }
  }
  .banner_log{
    background-image:url("#{$img}/register_banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    min-height: 820px;
  
    .form{
      position: relative;
    }
  
    @media only screen and (max-width: 1067px){
      background-position: bottom;
      min-height: 730px;
      }
  }
  .act_ins{
    position: absolute;
    top: 200px;
    width: 40%;
    height: auto;
    @media only screen and (max-width: 1167px){
      top: 450px;
      width: 80%;
      left: 10%;
    }
  
  }
  .error_msg{
    text-align: left;
      width: 100%;
      float: left;
      padding-top: 5px;
      padding-bottom: 5px;
      color: map-get($theme, eror);
  
  
  }
  