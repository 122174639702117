.banner {
  .content {
    top: 60%;
    left: 50%;
    width: 45%;
    height: auto;
    position: absolute;
    text-align: center;

    @media only screen and (max-width: 1199px){
      top: 54%;
    }
    @media only screen and (max-width: 991px)  {
      left: 40%;
      width: 55%;
    }

h1{
  @include font($fontB);
  font-size: 3.2em;

  @media only screen and (max-width: 1691px)  {
    font-size: 2.8em;
  }

  @media only screen and (max-width: 1199px)  {
    font-size: 2.2em;
  }
  @media only screen and (max-width: 991px)  {
    font-size: 1.5em;
  }

  @media only screen and (max-width: 576px){
    display: none;
  }
}
.banner_h4 {
  max-width: 80%;
  margin-left: 10%;
  padding-top: rem(25px);
  padding-bottom: rem(28px);
  color: map-get($theme, accent-drk);
  font-size: rem(24px);


  @media only screen and (max-width: 1691px)  {
    font-size: rem(20px);
    padding-top: rem(5px);
    padding-bottom: rem(15px);
  }
  @media only screen and (max-width: 991px)  {
    font-size: rem(14px);
    padding-top: rem(5px);
    padding-bottom: rem(15px);
  }

  

  @media only screen and (max-width: 576px){
    display: none;
  }
}
.app_wrap{
  margin-top: rem(45px);
  padding: rem(10px);
  border:1px solid;
  width: auto;
  border-color: #a2a096;
  background-color: map-get($theme, Bgmat );
  max-width: 40%;
  margin-left: 30%;
  border-radius: 25px;
  font-size: 1.2em; 

  @media only screen and (max-width: 1691px) and (min-width: 1290px)  {
    max-width: 45%;
    margin-left: 27.5%;
    font-size: 1em; 
    margin-top: rem(25px);
  }


  @media only screen and (max-width: 1289px) and (min-width: 768px)  {
    margin-top: rem(15px);
    max-width: 55%;
    margin-left: 22.5%;
    font-size: .7em; 
   
  }

  @media only screen and (max-width: 767px){
    max-width: 70%;
    margin-left: 15%;
    padding: rem(6px);
    font-size: .8em; 
    margin-top: rem(10px);
    
  }
  @media only screen and (max-width: 576px){
    max-width: 90%;
    margin-left: 5%;
    padding: rem(6px);
    font-size: .8em; 
    margin-top: rem(10px);
    
  }

span{
  padding-right: rem(20px);
  @media only screen and (max-width: 767px){
    padding-right: rem(2px);
  }
}
  i{
    padding-left: rem(10px);
    padding-right: rem(10px);
    font-size: 1.5em;
    border-left: 1px solid; 
    border-color: #a2a096;
    color: map-get($theme , text );
    @media only screen and (max-width: 767px){
      padding-right: rem(2px);
      padding-left: rem(2px);
      font-size: .7em;
    }
  }
}
.bt_lnrm{
  padding: rem(10px);
  background-color: map-get($theme, accent);
  color: map-get($theme, scWrite);
  min-width: 18%;
  font-size: rem(18px);
  @include font($fontB);
  box-shadow: 2px 5px 7px 0px rgba(0, 0, 0, 0.3);
  
  @media only screen and (max-width: 1691px) and (min-width: 1290px)  {
    padding: rem(8px);
    font-size: rem(15px);
  }

  @media only screen and (max-width: 1289px) and (min-width: 577px)  {
    padding: rem(4px);
    font-size: rem(13px);
  }
 
  @media only screen and (max-width: 576px){
    display: none;
  }
}
  }
}
.owl-dots{
  width: 1.5%;
  position: absolute;
  top: 50%;
 


 
  .owl-dot{

  
    span{
      border-color: map-get($theme, colorL );
      
  }
  
  }
  
  
  }
 
  