// ------------------------ //
// ***** WEIGHT CHART ***** //
// ------------------------ //
// #region -= WEIGHT CHART
// 100    Extra Light or Ultra Light
// 200    Light / Thin
// 300    Book or Demi / Thin
// 400    Normal or Regular
// 500    Medium
// 600    Semibold, Demibold
// 700    Bold
// 800    Black, Extra Bold or Heavy
// 900    Extra Black, Fat, Poster or Ultra Black
// #endregion -= WEIGHT CHART

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');