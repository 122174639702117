$mat: (
    font: $prFont,
    fontM: map-get($theme, fontM),
    color: $prColor,
    ctrlGutter: 1em,
    border: rgba(#000, 0.4),
    placeholder: rgba(#000, 0.42),
    kind: (
        primary: $prColor,
        info: #039BE5,
        warn: #FFB74D,
        error: #B92D00,
        success: #009688
    )
) !default;

$matColor: map-get($mat, kind);

$fw-m: map-get($mat, fontM) !default;

$placeholderColor: map-get($mat, placeholder) !default;

$labelFocus: map-get($mat, color) !default;
$errorColor: map-get($matColor, error) !default;
$successColor: map-get($matColor, success) !default;

$ctrlpadding: map-get($mat, ctrlGutter)  !default;

$matLblFont: map-get($mat, font) !default;
$matLblFontFocus: map-get($mat, fontM) !default;

$matBorderRadius: 4px;
